import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class StatisticsService extends ApiService {

  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  getUserStatistics(): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}stats/users`;
    return this._http.get(url);
  }

  getInstitutionStatistics(): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}stats/institutions`;
    return this._http.get(url);
  }

  loadServiceReceiverList(url?: string): Observable<any> {
    if (!url) {
      url = `${environment['API_URL']}${environment['API_VERSION']}billing`;
    }

    return this._http.get(url);
  }

  loadActiveUsers(date?: string): Observable<any> {
    let url = `${environment['API_URL']}${environment['API_VERSION']}stats/user-activity`;
    if (date) {
      url += `?date=${date}`;
    }

    return this._http.get(url);
  }

  loadMoreActivity(url: string): Observable<any> {
    return this._http.get(url);
  }

  loadBillableServiceReceivers(date?: string): Observable<any> {
    let url = `${environment['API_URL']}${environment['API_VERSION']}stats/billable-service-receivers`;
    if (date) {
      url += `?date=${date}`;
    }

    return this._http.get(url);
  }

  loadMore(url: string): Observable<any> {
    return this._http.get(url);
  }
}

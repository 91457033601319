import {Action} from '@ngrx/store';

export const LOAD_USER_STATISTICS = 'LOAD_USER_STATISTICS';
export const LOAD_USER_STATISTICS_SUCCESS = 'LOAD_USER_STATISTICS_SUCCESS';
export const LOAD_USER_STATISTICS_FAILURE = 'LOAD_USER_STATISTICS_FAILURE';
export const LOAD_INSTITUTION_STATISTICS = 'LOAD_INSTITUTION_STATISTICS';
export const LOAD_INSTITUTION_STATISTICS_SUCCESS = 'LOAD_INSTITUTION_STATISTICS_SUCCESS';
export const LOAD_INSTITUTION_STATISTICS_FAILURE = 'LOAD_INSTITUTION_STATISTICS_FAILURE';
export const LOAD_SERVICE_RECEIVER_LIST = 'LOAD_SERVICE_RECEIVER_LIST';
export const LOAD_SERVICE_RECEIVER_LIST_SUCCESS = 'LOAD_SERVICE_RECEIVER_LIST_SUCCESS';
export const LOAD_SERVICE_RECEIVER_LIST_FAILURE = 'LOAD_SERVICE_RECEIVER_LIST_FAILURE';
export const LOAD_USER_ACTIVITY = 'LOAD_USER_ACTIVITY';
export const LOAD_USER_ACTIVITY_SUCCESS = 'LOAD_USER_ACTIVITY_SUCCESS';
export const LOAD_USER_ACTIVITY_FAILURE = 'LOAD_USER_ACTIVITY_FAILURE';
export const LOAD_MORE_USER_ACTIVITY = 'LOAD_MORE_USER_ACTIVITY';
export const LOAD_MORE_USER_ACTIVITY_SUCCESS = 'LOAD_MORE_USER_ACTIVITY_SUCCESS';
export const LOAD_MORE_USER_ACTIVITY_FAILURE = 'LOAD_MORE_USER_ACTIVITY_FAILURE';
export const LOAD_BILLABLE_SERVICE_RECEIVERS = 'LOAD_BILLABLE_SERVICE_RECEIVERS';
export const LOAD_BILLABLE_SERVICE_RECEIVERS_SUCCESS = 'LOAD_BILLABLE_SERVICE_RECEIVERS_SUCCESS';
export const LOAD_BILLABLE_SERVICE_RECEIVERS_FAILURE = 'LOAD_BILLABLE_SERVICE_RECEIVERS_FAILURE';
export const LOAD_MORE_BILLABLE_SERVICE_RECEIVERS = 'LOAD_MORE_BILLABLE_SERVICE_RECEIVERS';
export const LOAD_MORE_BILLABLE_SERVICE_RECEIVERS_SUCCESS = 'LOAD_MORE_BILLABLE_SERVICE_RECEIVERS_SUCCESS';
export const LOAD_MORE_BILLABLE_SERVICE_RECEIVERS_FAILURE = 'LOAD_MORE_BILLABLE_SERVICE_RECEIVERS_FAILURE';

export class LoadUserStatistics implements Action {
  readonly type = LOAD_USER_STATISTICS;

  constructor(public payload?: any) {}
}

export class LoadUserStatisticsSuccess implements Action {
  readonly type = LOAD_USER_STATISTICS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUserStatisticsFailure implements Action {
  readonly type = LOAD_USER_STATISTICS_FAILURE;

  constructor(public payload: string) {}
}

export class LoadInstitutionStatistics implements Action {
  readonly type = LOAD_INSTITUTION_STATISTICS;

  constructor(public payload?: any) {}
}

export class LoadInstitutionStatisticsSuccess implements Action {
  readonly type = LOAD_INSTITUTION_STATISTICS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadInstitutionStatisticsFailure implements Action {
  readonly type = LOAD_INSTITUTION_STATISTICS_FAILURE;

  constructor(public payload: string) {}
}

export class LoadServiceReceiverList implements Action {
  readonly type = LOAD_SERVICE_RECEIVER_LIST;

  constructor(public payload?: string) {}
}

export class LoadServiceReceiverListSuccess implements Action {
  readonly type = LOAD_SERVICE_RECEIVER_LIST_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadServiceReceiverListFailure implements Action {
  readonly type = LOAD_SERVICE_RECEIVER_LIST_FAILURE;

  constructor(public payload: string) {}
}

export class LoadUserActivity implements Action {
  readonly type = LOAD_USER_ACTIVITY;

  constructor(public payload: string) {}
}

export class LoadUserActivitySuccess implements Action {
  readonly type = LOAD_USER_ACTIVITY_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUserActivityFailure implements Action {
  readonly type = LOAD_USER_ACTIVITY_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMoreUserActivity implements Action {
  readonly type = LOAD_MORE_USER_ACTIVITY;

  constructor(public payload: string) {}
}

export class LoadMoreUserActivitySuccess implements Action {
  readonly type = LOAD_MORE_USER_ACTIVITY_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreUserActivityFailure implements Action {
  readonly type = LOAD_MORE_USER_ACTIVITY_FAILURE;

  constructor(public payload: string) {}
}

export class LoadBillableServiceReceivers implements Action {
  readonly type = LOAD_BILLABLE_SERVICE_RECEIVERS;

  constructor(public payload: string) {}
}

export class LoadBillableServiceReceiversSuccess implements Action {
  readonly type = LOAD_BILLABLE_SERVICE_RECEIVERS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadBillableServiceReceiversFailure implements Action {
  readonly type = LOAD_BILLABLE_SERVICE_RECEIVERS_FAILURE;

  constructor(public payload: string) {}
}

export class LoadMoreBillableServiceReceivers implements Action {
  readonly type = LOAD_MORE_BILLABLE_SERVICE_RECEIVERS;

  constructor(public payload: string) {}
}

export class LoadMoreBillableServiceReceiversSuccess implements Action {
  readonly type = LOAD_MORE_BILLABLE_SERVICE_RECEIVERS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreBillableServiceReceiversFailure implements Action {
  readonly type = LOAD_MORE_BILLABLE_SERVICE_RECEIVERS_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadUserStatistics | LoadUserStatisticsSuccess | LoadUserStatisticsFailure | LoadInstitutionStatistics |
  LoadInstitutionStatisticsSuccess | LoadInstitutionStatisticsFailure | LoadServiceReceiverList | LoadServiceReceiverListSuccess |
  LoadServiceReceiverListFailure | LoadUserActivity | LoadUserActivitySuccess | LoadUserActivityFailure | LoadMoreUserActivity |
  LoadMoreUserActivitySuccess | LoadMoreUserActivityFailure | LoadBillableServiceReceivers |
  LoadBillableServiceReceiversSuccess | LoadBillableServiceReceiversFailure | LoadMoreBillableServiceReceivers |
  LoadMoreBillableServiceReceiversSuccess | LoadMoreBillableServiceReceiversFailure;

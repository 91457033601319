import * as TeamActions from './team.actions';
import {handleError} from '../helpers';

type Action = TeamActions.All;

export interface TeamState {
  loading: boolean;
  error: string;
  byUserId: any;
  entitiesById: any;
  entities: any[];
  next: string;
  membersByTeamId: any;
  adminsByTeamId: any;
}

const initialState: TeamState = {
  loading: false,
  error: null,
  byUserId: null,
  entitiesById: null,
  membersByTeamId: null,
  entities: null,
  next: null,
  adminsByTeamId: null,
};

export function reducer(state: TeamState = initialState, {type, payload}: Action): TeamState {
  switch (type) {
    case TeamActions.LOAD_TEAMS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.LOAD_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: payload.results,
        next: payload.next,
      };
    case TeamActions.LOAD_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.LOAD_MORE_TEAMS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.LOAD_MORE_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: [...state.entities, ...payload.results],
        next: payload.next,
      };
    case TeamActions.LOAD_MORE_TEAMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.LOAD_TEAM_BY_USER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.LOAD_TEAM_BY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        byUserId: {
          ...state.byUserId,
          [payload.userId]: payload.response.results,
        }
      };
    case TeamActions.LOAD_TEAM_BY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.DELETE_TEAM:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        byUserId: {
          ...state.byUserId,
          [payload.userId]: state.byUserId[payload.userId].filter((team) => team.id !== payload.teamId)
        }
      };
    case TeamActions.DELETE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.CREATE_TEAM:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        byUserId: {
          ...state.byUserId,
          [payload.userId]: (state.byUserId[payload.userId]) ? [...state.byUserId[payload.userId], payload.response] : [payload.response]
        },
      };
    case TeamActions.CREATE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.UPDATE_TEAM:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        entitiesById: {
          ...state.entitiesById,
          [payload.teamId]: payload.response,
        }
      };
    case TeamActions.UPDATE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.LOAD_TEAM_BY_ID:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.LOAD_TEAM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        entitiesById: {
          ...state.entitiesById,
          [payload.id]: payload,
        }
      };
    case TeamActions.LOAD_TEAM_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.LOAD_MEMBERS_BY_TEAM_ID:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.LOAD_MEMBERS_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        membersByTeamId: {
          ...state.membersByTeamId,
          [payload.teamId]: payload.response,
        }
      };
    case TeamActions.LOAD_MEMBERS_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.ADD_TEAM_MEMBERS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.ADD_TEAM_MEMBERS_SUCCESS:
      return {
        ...state,
        loading: false,
        membersByTeamId: {
          ...state.membersByTeamId,
          [payload.teamId]: payload.response,
        }
      };
    case TeamActions.ADD_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.REMOVE_TEAM_MEMBER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.REMOVE_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        membersByTeamId: {
          ...state.membersByTeamId,
          [payload.teamId]: payload.response,
        }
      };
    case TeamActions.REMOVE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.LOAD_ADMINS_BY_TEAM_ID:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.LOAD_ADMINS_BY_TEAM_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        adminsByTeamId: {
          ...state.adminsByTeamId,
          [payload.teamId]: payload.response,
        }
      };
    case TeamActions.LOAD_ADMINS_BY_TEAM_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.ADD_TEAM_ADMINS:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.ADD_TEAM_ADMINS_SUCCESS:
      return {
        ...state,
        loading: false,
        adminsByTeamId: {
          ...state.adminsByTeamId,
          [payload.teamId]: payload.response,
        }
      };
    case TeamActions.ADD_TEAM_ADMINS_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case TeamActions.REMOVE_TEAM_ADMIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case TeamActions.REMOVE_TEAM_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        adminsByTeamId: {
          ...state.adminsByTeamId,
          [payload.teamId]: payload.response,
        }
      };
    case TeamActions.REMOVE_TEAM_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    default:
      return state;
  }
}

export const getLoadingState = (state: TeamState) => state.loading;
export const getTeamsByUserId = (state: TeamState) => state.byUserId;
export const getError = (state: TeamState) => state.error;
export const getEntitiesById = (state: TeamState) => state.entitiesById;
export const getMembersByTeamId = (state: TeamState) => state.membersByTeamId;
export const getAdminsByTeamId = (state: TeamState) => state.adminsByTeamId;
export const getTeams = (state: TeamState) => state.entities;
export const getTeamsNextUrl = (state: TeamState) => state.next;

import {Action} from '@ngrx/store';
import {CrudOpState} from '../../enum';

export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const LOAD_USERS_FAILURE = 'LOAD_USERS_FAILURE';
export const LOAD_MORE_USERS = 'LOAD_MORE_USERS';
export const LOAD_MORE_USERS_SUCCESS = 'LOAD_MORE_USERS_SUCCESS';
export const LOAD_MORE_USERS_FAILURE = 'LOAD_MORE_USERS_FAILURE';
export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE';
export const SEARCH_USER_LIST = 'SEARCH_USER_LIST';
export const SEARCH_USER_LIST_SUCCESS = 'SEARCH_USER_LIST_SUCCESS';
export const SEARCH_USER_LIST_FAILURE = 'SEARCH_USER_LIST_FAILURE';
export const CLEAR_USER_SEARCH = 'CLEAR_USER_SEARCH';
export const LOAD_USER_BY_ID = 'LOAD_USER_BY_ID';
export const LOAD_USER_BY_ID_SUCCESS = 'LOAD_USER_BY_ID_SUCCESS';
export const LOAD_USER_BY_ID_FAILURE = 'LOAD_USER_BY_ID_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';
export const UNCONFIRM_USER = 'UNCONFIRM_USER';
export const UNCONFIRM_USER_SUCCESS = 'UNCONFIRM_USER_SUCCESS';
export const UNCONFIRM_USER_FAILURE = 'UNCONFIRM_USER_FAILURE';
export const LOAD_PAYMENT_INFO = 'LOAD_PAYMENT_INFO';
export const LOAD_PAYMENT_INFO_SUCCESS = 'LOAD_PAYMENT_INFO_SUCCESS';
export const LOAD_PAYMENT_INFO_FAILURE = 'LOAD_PAYMENT_INFO_FAILURE';
export const UPDATE_BILLING_INFO = 'UPDATE_BILLING_INFO';
export const UPDATE_BILLING_INFO_SUCCESS = 'UPDATE_BILLING_INFO_SUCCESS';
export const UPDATE_BILLING_INFO_FAILURE = 'UPDATE_BILLING_INFO_FAILURE';
export const CREATE_BILLING_INFO = 'CREATE_BILLING_INFO';
export const CREATE_BILLING_INFO_SUCCESS = 'CREATE_BILLING_INFO_SUCCESS';
export const CREATE_BILLING_INFO_FAILURE = 'CREATE_BILLING_INFO_FAILURE';
export const LOAD_USER_ACTIVITY_BY_ID = 'LOAD_USER_ACTIVITY_BY_ID';
export const LOAD_USER_ACTIVITY_BY_ID_SUCCESS = 'LOAD_USER_ACTIVITY_BY_ID_SUCCESS';
export const LOAD_USER_ACTIVITY_BY_ID_FAILURE = 'LOAD_USER_ACTIVITY_BY_ID_FAILURE';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const SET_USER_CRUD_STATE = 'SET_USER_CRUD_STATE';

export class LoadUsers implements Action {
  readonly type = LOAD_USERS;

  constructor(public payload?: {limit: number, offset: number}) {}
}

export class LoadUsersSuccess implements Action {
  readonly type = LOAD_USERS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUsersFailure implements Action {
  readonly type = LOAD_USERS_FAILURE;

  constructor(public payload: string) {}
}
export class LoadMoreUsers implements Action {
  readonly type = LOAD_MORE_USERS;

  constructor(public payload: string) {}
}

export class LoadMoreUsersSuccess implements Action {
  readonly type = LOAD_MORE_USERS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreUsersFailure implements Action {
  readonly type = LOAD_MORE_USERS_FAILURE;

  constructor(public payload: string) {}
}

export class SearchUser implements Action {
  readonly type = SEARCH_USER;

  constructor(public payload: string) {}
}

export class SearchUserSuccess implements Action {
  readonly type = SEARCH_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchUserFailure implements Action {
  readonly type = SEARCH_USER_FAILURE;

  constructor(public payload: string) {}
}

export class SearchUserList implements Action {
  readonly type = SEARCH_USER_LIST;

  constructor(public payload: string) {}
}

export class SearchUserListSuccess implements Action {
  readonly type = SEARCH_USER_LIST_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchUserListFailure implements Action {
  readonly type = SEARCH_USER_LIST_FAILURE;

  constructor(public payload: string) {}
}

export class ClearUserSearch implements Action {
  readonly type = CLEAR_USER_SEARCH;

  constructor(public payload?: any) {}
}

export class LoadUserById implements Action {
  readonly type = LOAD_USER_BY_ID;

  constructor(public payload: string) {}
}

export class LoadUserByIdSuccess implements Action {
  readonly type = LOAD_USER_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUserByIdFailure implements Action {
  readonly type = LOAD_USER_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;

  constructor(public payload: any) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = UPDATE_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateUserFailure implements Action {
  readonly type = UPDATE_USER_FAILURE;

  constructor(public payload: string) {}
}

export class UpdateUserProfile implements Action {
  readonly type = UPDATE_USER_PROFILE;

  constructor(public payload: {userId: string, userprofile: any}) {}
}

export class UpdateUserProfileSuccess implements Action {
  readonly type = UPDATE_USER_PROFILE_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateUserProfileFailure implements Action {
  readonly type = UPDATE_USER_PROFILE_FAILURE;

  constructor(public payload: string) {}
}

export class SetPassword implements Action {
  readonly type = SET_PASSWORD;

  constructor(public payload: {userId: string, password: string}) {}
}

export class SetPasswordSuccess implements Action {
  readonly type = SET_PASSWORD_SUCCESS;

  constructor(public payload: any) {}
}

export class SetPasswordFailure implements Action {
  readonly type = SET_PASSWORD_FAILURE;

  constructor(public payload: string) {}
}

export class UnConfirmUser implements Action {
  readonly type = UNCONFIRM_USER;

  constructor(public payload: string) {}
}

export class UnConfirmUserSuccess implements Action {
  readonly type = UNCONFIRM_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class UnConfirmUserFailure implements Action {
  readonly type = UNCONFIRM_USER_FAILURE;

  constructor(public payload: string) {}
}

export class LoadPaymentInfo implements Action {
  readonly type = LOAD_PAYMENT_INFO;

  constructor(public payload: string) {}
}

export class LoadPaymentInfoSuccess implements Action {
  readonly type = LOAD_PAYMENT_INFO_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadPaymentInfoFailure implements Action {
  readonly type = LOAD_PAYMENT_INFO_FAILURE;

  constructor(public payload: string) {}
}

export class UpdateBillingInfo implements Action {
  readonly type = UPDATE_BILLING_INFO;

  constructor(public payload: any) {}
}

export class UpdateBillingInfoSuccess implements Action {
  readonly type = UPDATE_BILLING_INFO_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateBillingInfoFailure implements Action {
  readonly type = UPDATE_BILLING_INFO_FAILURE;

  constructor(public payload: string) {}
}

export class CreateBillingInfo implements Action {
  readonly type = CREATE_BILLING_INFO;

  constructor(public payload: any) {}
}

export class CreateBillingInfoSuccess implements Action {
  readonly type = CREATE_BILLING_INFO_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateBillingInfoFailure implements Action {
  readonly type = CREATE_BILLING_INFO_FAILURE;

  constructor(public payload: string) {}
}

export class LoadUserActivityById implements Action {
  readonly type = LOAD_USER_ACTIVITY_BY_ID;

  constructor(public payload: string) {}
}

export class LoadUserActivityByIdSuccess implements Action {
  readonly type = LOAD_USER_ACTIVITY_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadUserActivityByIdFailure implements Action {
  readonly type = LOAD_USER_ACTIVITY_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class DeleteUser implements Action {
  readonly type = DELETE_USER;

  constructor(public payload: string) {}
}

export class DeleteUserSuccess implements Action {
  readonly type = DELETE_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteUserFailure implements Action {
  readonly type = DELETE_USER_FAILURE;

  constructor(public payload: string) {}
}

export class SetUserCrudState implements Action {
  readonly type = SET_USER_CRUD_STATE;

  constructor(public payload?: CrudOpState) {}
}

export type All = LoadUsers | LoadUsersSuccess | LoadUsersFailure | LoadMoreUsers | LoadMoreUsersSuccess | LoadMoreUsersFailure |
  SearchUser | SearchUserSuccess | SearchUserFailure | ClearUserSearch | LoadUserById | LoadUserByIdSuccess | LoadUserByIdFailure |
  UpdateUser | UpdateUserSuccess | UpdateUserFailure | UpdateUserProfile | UpdateUserProfileSuccess | UpdateUserProfileFailure |
  SetPassword | SetPasswordSuccess | SetPasswordFailure | UnConfirmUser | UnConfirmUserSuccess | UnConfirmUserFailure | LoadPaymentInfo |
  LoadPaymentInfoSuccess | LoadPaymentInfoFailure | UpdateBillingInfo | UpdateBillingInfoSuccess | UpdateBillingInfoFailure |
  CreateBillingInfo | CreateBillingInfoSuccess | CreateBillingInfoFailure | LoadUserActivityById | LoadUserActivityByIdSuccess |
  LoadUserActivityByIdFailure | SearchUserList | SearchUserListSuccess | SearchUserListFailure | DeleteUser | DeleteUserSuccess |
  DeleteUserFailure | SetUserCrudState;

export enum AuthenticationState {
  UNAUTHENTICATED = 'Unauthenticated',
  AUTHENTICATING = 'Authenticating',
  AUTHENTICATED = 'Authenticated',
  TOKEN_EXPIRED = 'TokenExpired',
  VERIFY_MFA_TOKEN = 'VerifyMfaToken',
  ADD_MFA = 'AddMfa',
}

export enum ToastType {
  SUCCESS = 'ToastSuccess',
  ERROR = 'ToastError'
}

export enum CrudOpState {
  CREATING = 'Creating',
  CREATED = 'Created',
  DELETING = 'Deleting',
  DELETED = 'Deleted',
  USER_CREATED = 'UserCreated',
  INSTITUTION_CREATED = 'InstitutionCreated',
  GROUP_CREATED = 'GroupCreated',
  CREATING_MANAGER = 'CreatingManager',
  MANAGER_CREATED = 'ManagerCreated',
  MUNICIPALITY_CREATED = 'MunicipalityCreated',
}

export enum FormMode {
  CREATE = 'Create',
  EDIT = 'Edit'
}

export enum FormType {
  USER_FORM = 'UserForm',
  INSTITUTION_FORM = 'InstitutionForm',
  GROUP_FORM = 'GroupForm',
  RELATION_FORM = 'RelationForm',
}

export enum UserFilter {
  IS_SERVICE_RECEIVER = 'is_service_receiver',
  IS_EMPLOYEE = 'is_employee',
  IS_RELATIVE= 'is_relative',
}

export enum EntityType {
  GROUP = 'Group',
  INSTITUTION = 'Institution',
  USER = 'User',
}

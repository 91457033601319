import * as BillingActions from './billing.actions';
import {handleError} from '../helpers';
import {CrudOpState} from '../../enum';

type Action = BillingActions.All;

export interface BillingState {
  loading: boolean;
  error: string;
  byResponsibleId: any;
  crudState: CrudOpState;
}

const initialState: BillingState = {
  loading: false,
  error: null,
  byResponsibleId: null,
  crudState: null,
};

export function reducer(state: BillingState = initialState, {type, payload}: Action): BillingState {
  switch (type) {
    case BillingActions.LOAD_BILLING_RESPONSIBILITIES_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case BillingActions.LOAD_BILLING_RESPONSIBILITIES_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        byResponsibleId: Object.assign({}, state.byResponsibleId, {
          [payload.responsibleId]: payload.results,
        })
      });
    case BillingActions.LOAD_BILLING_RESPONSIBILITIES_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case BillingActions.CREATE_BULK_BILLING_INFO:
      return Object.assign({}, state, {
        crudState: CrudOpState.CREATING,
        error: null
      });
    case BillingActions.CREATE_BULK_BILLING_INFO_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        crudState: CrudOpState.CREATED,
        byResponsibleId: Object.assign({}, state.byResponsibleId, {
          [payload.responsibleId]: payload.results,
        })
      });
    case BillingActions.CREATE_BULK_BILLING_INFO_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        crudState: null,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: BillingState) => state.loading;
export const getInfoByResponsibleId = (state: BillingState) => state.byResponsibleId;
export const getBillingInfoCrudState = (state: BillingState) => state.crudState;

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class EulaService extends ApiService {
  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  loadResponders(version: number): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}eula/${version}/responses`;
    return this._http.get(url);
  }
}

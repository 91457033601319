import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class TeamService extends ApiService {
  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  load(): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}teams`;
    return this._http.get(url);
  }

  loadMore(url: string): Observable<any> {
    return this._http.get(url);
  }

  createTeam(serviceReceiverId: string, name: string, description: string, adminIds: string[]): Observable<any> {
    let team;

    const url = `${environment['API_URL']}${environment['API_VERSION']}teams`;
    return this._http.post(url, {service_receiver_id: serviceReceiverId, name, description, admins: adminIds})
      .pipe(
        map((response) => team = response),
        switchMap(() => this.addAdmins(team.id, adminIds)), // Add Admins
        switchMap(() => this._http.get(`${environment['API_URL']}${environment['API_VERSION']}auth`)), // Get authenticated user
        switchMap((superuser: any) => this.removeAdmin(team.id, superuser.id)), // Remove authenticated user as admin
        map(() => ({
          userId: serviceReceiverId,
          response: team,
        }))
      );
  }

  updateTeam(teamId: string, name: string, description: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}teams/${teamId}`;
    return this._http.patch(url, {name, description})
      .pipe(
        map((response) => ({
          teamId,
          response,
        }))
      );
  }

  loadTeamById(teamId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}teams/${teamId}`;
    return this._http.get(url);
  }

  deleteTeam(userId: string, teamId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}teams/${teamId}`;
    return this._http.delete(url)
      .pipe(
        map(() => ({userId, teamId}))
      );
  }

  loadMembers(teamId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}teams/${teamId}/members`;
    return this._http.get(url)
      .pipe(
        map((response) => ({
          teamId,
          response,
        }))
      );
  }

  addMembers(teamId: string, userIds: string[]): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}teams/${teamId}/members`;
    return this._http.post(url, {user_ids: userIds})
      .pipe(
        map((response) => ({
          teamId,
          response,
        }))
      );
  }

  removeMember(teamId: string, userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}teams/${teamId}/members/${userId}`;
    return this._http.delete(url)
      .pipe(
        map((response) => ({
          teamId,
          response,
        }))
      );
  }

  loadAdmins(teamId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}teams/${teamId}/admins`;
    return this._http.get(url)
      .pipe(
        map((response) => ({
          teamId,
          response,
        }))
      );
  }

  addAdmins(teamId: string, userIds: string[]): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}teams/${teamId}/admins`;
    return this._http.post(url, {user_ids: userIds})
      .pipe(
        map((response) => ({
          teamId,
          response,
        }))
      );
  }

  removeAdmin(teamId: string, userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}teams/${teamId}/admins/${userId}`;
    return this._http.delete(url)
      .pipe(
        map((response) => ({
          teamId,
          response,
        }))
      );
  }
}

import * as fromAuth from './auth/auth.reducer';
import * as fromStatistics from './statistics/statistics.reducer';
import * as fromUser from './user/user.reducer';
import * as fromForm from './form/form.reducer';
import * as fromToast from './toast/toast.reducer';
import * as fromCrud from './crud/crud.reducer';
import * as fromUi from './ui/ui.reducer';
import * as fromInstitution from './institution/institution.reducer';
import * as fromAddress from './address/address.reducer';
import * as fromGroup from './group/group.reducer';
import * as fromMunicipalities from './municipality/municipality.reducer';
import * as fromRelation from './relation/relation.reducer';
import * as fromEula from './eula/eula.reducer';
import * as fromBilling from './billing/billing.reducer';
import * as fromTeam from './team/team.reducer';
import {createSelector} from 'reselect';

export interface IAppState {
  auth: fromAuth.AuthState;
  statistics: fromStatistics.StatisticsState;
  user: fromUser.UserState;
  form: fromForm.FormState;
  toast: fromToast.ToastState;
  crud: fromCrud.CrudState;
  ui: fromUi.UiState;
  institution: fromInstitution.InstitutionState;
  address: fromAddress.AddressState;
  group: fromGroup.GroupState;
  municipalities: fromMunicipalities.MunicipalityState;
  relation: fromRelation.RelationState;
  eula: fromEula.EulaState;
  billing: fromBilling.BillingState;
  team: fromTeam.TeamState;
}

export const reducers = {
  auth: fromAuth.reducer,
  statistics: fromStatistics.reducer,
  user: fromUser.reducer,
  form: fromForm.reducer,
  toast: fromToast.reducer,
  crud: fromCrud.reducer,
  ui: fromUi.reducer,
  institution: fromInstitution.reducer,
  address: fromAddress.reducer,
  group: fromGroup.reducer,
  municipalities: fromMunicipalities.reducer,
  relation: fromRelation.reducer,
  eula: fromEula.reducer,
  billing: fromBilling.reducer,
  team: fromTeam.reducer,
};

export const getAuthState = (state: IAppState) => state.auth;
export const getAuthenticationState = createSelector(getAuthState, fromAuth.getAuthenticationState);
export const getAuthLoadingState = createSelector(getAuthState, fromAuth.getLoadingState);
export const getAuthUser = createSelector(getAuthState, fromAuth.getAuthUser);
export const getAuthError = createSelector(getAuthState, fromAuth.getError);
export const getAuthQRCode = createSelector(getAuthState, fromAuth.getQrCode);

export const getStatisticsState = (state: IAppState) => state.statistics;
export const getStatisticsLoadingState = createSelector(getStatisticsState, fromStatistics.getLoadingState);
export const getUserStatistics = createSelector(getStatisticsState, fromStatistics.getUsers);
export const getInstitutionStatistics = createSelector(getStatisticsState, fromStatistics.getInstitutions);
export const getServiceReceiverList = createSelector(getStatisticsState, fromStatistics.getServiceReceiverList);
export const getServiceReceiverListNextUrl = createSelector(getStatisticsState, fromStatistics.getServiceReceiverNextUrl);
export const getUserActivity = createSelector(getStatisticsState, fromStatistics.getUserActivity);
export const getUserActivityNextUrl = createSelector(getStatisticsState, fromStatistics.getUserActivityNext);
export const getBillableServiceReceivers = createSelector(getStatisticsState, fromStatistics.getBillableServiceReceivers);
export const getBillableServiceReceiversNextUrl = createSelector(getStatisticsState, fromStatistics.getBillableServiceReceiversNextUrl);

export const getUserState = (state: IAppState) => state.user;
export const getUserLoadingState = createSelector(getUserState, fromUser.getLoadingState);
export const getUsers = createSelector(getUserState, fromUser.getEntities);
export const getUserSearchResults = createSelector(getUserState, fromUser.getSearchResults);
export const getUserListSearchResults = createSelector(getUserState, fromUser.getListSearchResults);
export const getUsersById = createSelector(getUserState, fromUser.getEntitiesById);
export const getUserError = createSelector(getUserState, fromUser.getError);
export const getUserNext = createSelector(getUserState, fromUser.getNext);
export const getBillingInfoByUser = createSelector(getUserState, fromUser.getBillingInfoByUser);
export const getUserActivityById = createSelector(getUserState, fromUser.getActivityByUserId);
export const getUserCrudState = createSelector(getUserState, fromUser.getCrudState);

export const getFormState = (state: IAppState) => state.form;
export const getUserFormError = createSelector(getFormState, fromForm.getUserError);
export const getInstitutionFormError = createSelector(getFormState, fromForm.getInstitutionError);
export const getGroupFormError = createSelector(getFormState, fromForm.getGroupError);
export const getRelationFormError = createSelector(getFormState, fromForm.getRelationError);

export const getToastState = (state: IAppState) => state.toast;
export const getToast = createSelector(getToastState, fromToast.getToast);

export const getCrudState = (state: IAppState) => state.crud;
export const getCrudLoadingState = createSelector(getCrudState, fromCrud.getLoadingState);
export const getCrudOpState = createSelector(getCrudState, fromCrud.getState);
export const getCrudEntity = createSelector(getCrudState, fromCrud.getEntity);

export const getUiState = (state: IAppState) => state.ui;
export const getUiShowDropDown = createSelector(getUiState, fromUi.getShowDropDown);
export const getShowCreateRelationForm = createSelector(getUiState, fromUi.getShowCreateRelationForm);
export const getShowAddUsersModal = createSelector(getUiState, fromUi.getShowAddUsersModal);
export const getShowAddToGroupModal = createSelector(getUiState, fromUi.getShowAddToGroupModal);
export const getShowAddToInstitutionModal = createSelector(getUiState, fromUi.getShowAddToInstitutionModal);
export const getShowCreateManagerModal = createSelector(getUiState, fromUi.getShowCreateManagerModal);

export const getInstitutionState = (state: IAppState) => state.institution;
export const getInstitutionLoadingState = createSelector(getInstitutionState, fromInstitution.getLoadingState);
export const getInstitutions = createSelector(getInstitutionState, fromInstitution.getEntities);
export const getInstitutionSearchResults = createSelector(getInstitutionState, fromInstitution.getSearchResults);
export const getInstitutionsById = createSelector(getInstitutionState, fromInstitution.getEntitiesById);
export const getInstitutionError = createSelector(getInstitutionState, fromInstitution.getError);
export const getInstitutionNext = createSelector(getInstitutionState, fromInstitution.getNext);
export const getInstitutionServiceReceiversByInstitutionId = createSelector(getInstitutionState, fromInstitution.getServiceReceivers);
export const getInstitutionEmployeesByInstitutionId = createSelector(getInstitutionState, fromInstitution.getEmployees);
export const getInstitutionsByUser = createSelector(getInstitutionState, fromInstitution.getInstitutionsByUser);
export const getInstitutionLoadingChildById = createSelector(getInstitutionState, fromInstitution.getLoadingChildById);
export const getInstitutionHierarchyById = createSelector(getInstitutionState, fromInstitution.getHierarchyById);

export const getAddressState = (state: IAppState) => state.address;
export const getAddressLoadingState = createSelector(getAddressState, fromAddress.getLoadingState);
export const getAddressSearchResults = createSelector(getAddressState, fromAddress.getSearchResults);
export const getAddressesById = createSelector(getAddressState, fromAddress.getEntitiesById);
export const getCountries = createSelector(getAddressState, fromAddress.getCountries);

export const getGroupState = (state: IAppState) => state.group;
export const getGroupLoadingState = createSelector(getGroupState, fromGroup.getLoadingState);
export const getGroups = createSelector(getGroupState, fromGroup.getEntities);
export const getGroupSearchResults = createSelector(getGroupState, fromGroup.getSearchResults);
export const getGroupsById = createSelector(getGroupState, fromGroup.getEntitiesById);
export const getGroupError = createSelector(getGroupState, fromGroup.getError);
export const getGroupNext = createSelector(getGroupState, fromGroup.getNext);
export const getAdminsByGroupId = createSelector(getGroupState, fromGroup.getAdminsByGroupId);
export const getMembersByGroupId = createSelector(getGroupState, fromGroup.getMembersByGroupId);
export const getGroupsByUser = createSelector(getGroupState, fromGroup.getGroupsByUser);

export const getMunicipalityState = (state: IAppState) => state.municipalities;
export const getMunicipalityLoadingState = createSelector(getMunicipalityState, fromMunicipalities.getLoadingState);
export const getActiveMunicipalities = createSelector(getMunicipalityState, fromMunicipalities.getActiveEntities);
export const getInactiveMunicipalities = createSelector(getMunicipalityState, fromMunicipalities.getInactiveEntities);
export const getMunicipalitiesById = createSelector(getMunicipalityState, fromMunicipalities.getEntitiesById);
export const getAllMunicipalities = createSelector(getMunicipalityState, fromMunicipalities.getAll);

export const getRelationState = (state: IAppState) => state.relation;
export const getRelationLoadingState = createSelector(getRelationState, fromRelation.getLoadingState);
export const getRelationError = createSelector(getRelationState, fromRelation.getError);
export const getRelationsByUser = createSelector(getRelationState, fromRelation.getEntitiesByUser);

export const getEulaState = (state: IAppState) => state.eula;
export const getEulaRespondersByVersion = createSelector(getEulaState, fromEula.getRespondersByVersion);
export const getEulaError = createSelector(getEulaState, fromEula.getError);

export const getBillingState = (state: IAppState) => state.billing;
export const getBillingLoadingState = createSelector(getBillingState, fromBilling.getLoadingState);
export const getBillingInfoByResponsibleId = createSelector(getBillingState, fromBilling.getInfoByResponsibleId);
export const getBillingInfoCrudState = createSelector(getBillingState, fromBilling.getBillingInfoCrudState);

export const getTeamState = (state: IAppState) => state.team;
export const getTeamLoadingState = createSelector(getTeamState, fromTeam.getLoadingState);
export const getTeams = createSelector(getTeamState, fromTeam.getTeams);
export const getTeamsNextUrl = createSelector(getTeamState, fromTeam.getTeamsNextUrl);
export const getTeamsByUserId = createSelector(getTeamState, fromTeam.getTeamsByUserId);
export const getTeamError = createSelector(getTeamState, fromTeam.getError);
export const getTeamById = createSelector(getTeamState, fromTeam.getEntitiesById);
export const getMembersByTeamId = createSelector(getTeamState, fromTeam.getMembersByTeamId);
export const getAdminsByTeamId = createSelector(getTeamState, fromTeam.getAdminsByTeamId);

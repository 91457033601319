import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as rootState from './store';
import {AuthenticationState} from './enum';
import {Observable, Subscription} from 'rxjs';
import {NavigationStart, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {CheckAuth, GetAuthUser, UnAuth} from './store/auth/auth.actions';
import {SetNavBarMenuDropDownOpen} from './store/ui/ui.actions';

@Component({
  selector: 'app-root, [app-root]',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean;
  authUser$: Observable<any>;

  private _showCreateDropDown: boolean;
  private _currentUrl: string;
  private _eventSubscription: Subscription;
  private _authStateSubscription: Subscription;
  private _navBarCreateDropDownSubscription: Subscription;

  constructor(private _store: Store<rootState.IAppState>, private _router: Router) {}

  ngOnInit(): void {
    this._authStateSubscription = this._store.select(rootState.getAuthenticationState)
      .subscribe(state => this._handleAuthState(state));

    this.authUser$ = this._store.select(rootState.getAuthUser);

    this._navBarCreateDropDownSubscription = this._store.select(rootState.getUiShowDropDown)
      .pipe(map(value => this._showCreateDropDown = value))
      .subscribe();

    this._eventSubscription = this._router.events
      .pipe(
        map((event) => {
          if (event instanceof NavigationStart) {
            this._store.dispatch(new SetNavBarMenuDropDownOpen(false));
            this._currentUrl = event.url;
            this._store.dispatch(new CheckAuth());
          }
        })
      )
      .subscribe();

    this._store.dispatch(new CheckAuth());
  }

  private _handleAuthState(state: AuthenticationState) {
    if (state === AuthenticationState.UNAUTHENTICATED) {
      this.isAuthenticated = false;
      this._router.navigate(['login']);
    } else if (state === AuthenticationState.AUTHENTICATED) {
      if (this._currentUrl === '/login') {
        this._router.navigate(['dashboard']);
      }

      this.isAuthenticated = true;
      this._store.dispatch(new GetAuthUser());
    } else if (state === AuthenticationState.TOKEN_EXPIRED) {
      this._store.dispatch(new UnAuth());
    }
  }

  ngOnDestroy(): void {
    if (this._authStateSubscription) {
      this._authStateSubscription.unsubscribe();
    }

    if (this._eventSubscription) {
      this._eventSubscription.unsubscribe();
    }

    if (this._navBarCreateDropDownSubscription) {
      this._navBarCreateDropDownSubscription.unsubscribe();
    }
  }

}

import {Action} from '@ngrx/store';

export const LOAD_EULA_RESPONDERS = 'LOAD_EULA_RESPONDERS';
export const LOAD_EULA_RESPONDERS_SUCCESS = 'LOAD_EULA_RESPONDERS_SUCCESS';
export const LOAD_EULA_RESPONDERS_FAILURE = 'LOAD_EULA_RESPONDERS_FAILURE';

export class LoadEulaResponders implements Action {
  readonly type = LOAD_EULA_RESPONDERS;

  constructor(public payload: number) {}
}

export class LoadEulaRespondersSuccess implements Action {
  readonly type = LOAD_EULA_RESPONDERS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadEulaRespondersFailure implements Action {
  readonly type = LOAD_EULA_RESPONDERS_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadEulaResponders | LoadEulaRespondersSuccess | LoadEulaRespondersFailure;

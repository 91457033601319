import * as StatisticsActions from './statistics.actions';

type Action = StatisticsActions.All;

export interface StatisticsState {
  loading: boolean;
  users: any;
  institutions: any;
  serviceReceiverList: any[];
  serviceReceiverNext: string;
  userActivity: any[];
  activityNext: string;
  error: string;
  billableServiceReceivers: any[];
  billableServiceReceiversNext: string;
}

const initialState = {
  loading: false,
  users: null,
  institutions: null,
  serviceReceiverList: null,
  serviceReceiverNext: null,
  userActivity: null,
  activityNext: null,
  error: null,
  billableServiceReceivers: null,
  billableServiceReceiversNext: null,
};

export function reducer(state: StatisticsState = initialState, {type, payload}: Action): StatisticsState {
  switch (type) {
    case StatisticsActions.LOAD_USER_STATISTICS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case StatisticsActions.LOAD_USER_STATISTICS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        users: payload
      });
    case StatisticsActions.LOAD_USER_STATISTICS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case StatisticsActions.LOAD_INSTITUTION_STATISTICS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case StatisticsActions.LOAD_INSTITUTION_STATISTICS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        institutions: payload
      });
    case StatisticsActions.LOAD_INSTITUTION_STATISTICS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case StatisticsActions.LOAD_SERVICE_RECEIVER_LIST:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case StatisticsActions.LOAD_SERVICE_RECEIVER_LIST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        serviceReceiverList: (state.serviceReceiverList)
          ? [].concat(...state.serviceReceiverList, payload.results)
          : payload.results,
        serviceReceiverNext: payload.next
      });
    case StatisticsActions.LOAD_SERVICE_RECEIVER_LIST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case StatisticsActions.LOAD_USER_ACTIVITY:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case StatisticsActions.LOAD_USER_ACTIVITY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        userActivity: payload.results,
        activityNext: payload.next,
      });
    case StatisticsActions.LOAD_USER_ACTIVITY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case StatisticsActions.LOAD_MORE_USER_ACTIVITY:
      return {
        ...state,
        loading: true,
        error: null
      };
    case StatisticsActions.LOAD_MORE_USER_ACTIVITY_SUCCESS:
      return {
        ...state,
        loading: false,
        userActivity: [...state.userActivity, ...payload.results],
        activityNext: payload.next,
      };
    case StatisticsActions.LOAD_MORE_USER_ACTIVITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error
      };
    case StatisticsActions.LOAD_BILLABLE_SERVICE_RECEIVERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case StatisticsActions.LOAD_BILLABLE_SERVICE_RECEIVERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        billableServiceReceivers: payload.results,
        billableServiceReceiversNext: payload.next
      });
    case StatisticsActions.LOAD_BILLABLE_SERVICE_RECEIVERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case StatisticsActions.LOAD_MORE_BILLABLE_SERVICE_RECEIVERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case StatisticsActions.LOAD_MORE_BILLABLE_SERVICE_RECEIVERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        billableServiceReceivers: [].concat(...state.billableServiceReceivers, ...payload.results),
        billableServiceReceiversNext: payload.next
      });
    case StatisticsActions.LOAD_MORE_BILLABLE_SERVICE_RECEIVERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: StatisticsState) => state.loading;
export const getUsers = (state: StatisticsState) => state.users;
export const getInstitutions = (state: StatisticsState) => state.institutions;
export const getServiceReceiverList = (state: StatisticsState) => state.serviceReceiverList;
export const getServiceReceiverNextUrl = (state: StatisticsState) => state.serviceReceiverNext;
export const getUserActivity = (state: StatisticsState) => state.userActivity;
export const getUserActivityNext = (state: StatisticsState) => state.activityNext;
export const getBillableServiceReceivers = (state: StatisticsState) => state.billableServiceReceivers;
export const getBillableServiceReceiversNextUrl = (state: StatisticsState) => state.billableServiceReceiversNext;

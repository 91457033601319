import {Action} from '@ngrx/store';

export const LOAD_TEAMS = 'LOAD_TEAMS';
export const LOAD_TEAMS_SUCCESS = 'LOAD_TEAMS_SUCCESS';
export const LOAD_TEAMS_FAILURE = 'LOAD_TEAMS_FAILURE';

export class LoadTeams implements Action {
  readonly type = LOAD_TEAMS;

  constructor(public payload?: string) {}
}

export class LoadTeamsSuccess implements Action {
  readonly type = LOAD_TEAMS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadTeamsFailure implements Action {
  readonly type = LOAD_TEAMS_FAILURE;

  constructor(public payload: string) {}
}

export const LOAD_MORE_TEAMS = 'LOAD_MORE_TEAMS';
export const LOAD_MORE_TEAMS_SUCCESS = 'LOAD_MORE_TEAMS_SUCCESS';
export const LOAD_MORE_TEAMS_FAILURE = 'LOAD_MORE_TEAMS_FAILURE';

export class LoadMoreTeams implements Action {
  readonly type = LOAD_MORE_TEAMS;

  constructor(public payload: string) {}
}

export class LoadMoreTeamsSuccess implements Action {
  readonly type = LOAD_MORE_TEAMS_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMoreTeamsFailure implements Action {
  readonly type = LOAD_MORE_TEAMS_FAILURE;

  constructor(public payload: string) {}
}

export const LOAD_TEAM_BY_USER = 'LOAD_TEAM_BY_USER';
export const LOAD_TEAM_BY_USER_SUCCESS = 'LOAD_TEAM_BY_USER_SUCCESS';
export const LOAD_TEAM_BY_USER_FAILURE = 'LOAD_TEAM_BY_USER_FAILURE';

export class LoadTeamByUser implements Action {
  readonly type = LOAD_TEAM_BY_USER;

  constructor(public payload: string) {}
}

export class LoadTeamByUserSuccess implements Action {
  readonly type = LOAD_TEAM_BY_USER_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadTeamByUserFailure implements Action {
  readonly type = LOAD_TEAM_BY_USER_FAILURE;

  constructor(public payload: string) {}
}

export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILURE = 'DELETE_TEAM_FAILURE';

export class DeleteTeam implements Action {
  readonly type = DELETE_TEAM;

  constructor(public payload: {userId: string, teamId: string}) {}
}

export class DeleteTeamSuccess implements Action {
  readonly type = DELETE_TEAM_SUCCESS;

  constructor(public payload: any) {}
}

export class DeleteTeamFailure implements Action {
  readonly type = DELETE_TEAM_FAILURE;

  constructor(public payload: string) {}
}

export const CREATE_TEAM = 'CREATE_TEAM';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAILURE = 'CREATE_TEAM_FAILURE';

export class CreateTeam implements Action {
  readonly type = CREATE_TEAM;

  constructor(public payload: {service_receiver_id: string, name: string, description?: string, admins: string[]}) {}
}

export class CreateTeamSuccess implements Action {
  readonly type = CREATE_TEAM_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateTeamFailure implements Action {
  readonly type = CREATE_TEAM_FAILURE;

  constructor(public payload: string) {}
}


export const UPDATE_TEAM = 'UPDATE_TEAM';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAILURE = 'UPDATE_TEAM_FAILURE';

export class UpdateTeam implements Action {
  readonly type = UPDATE_TEAM;

  constructor(public payload: {id: string, name?: string, description?: string}) {}
}

export class UpdateTeamSuccess implements Action {
  readonly type = UPDATE_TEAM_SUCCESS;

  constructor(public payload: any) {}
}

export class UpdateTeamFailure implements Action {
  readonly type = UPDATE_TEAM_FAILURE;

  constructor(public payload: string) {}
}

export const LOAD_TEAM_BY_ID = 'LOAD_TEAM_BY_ID';
export const LOAD_TEAM_BY_ID_SUCCESS = 'LOAD_TEAM_BY_ID_SUCCESS';
export const LOAD_TEAM_BY_ID_FAILURE = 'LOAD_TEAM_BY_ID_FAILURE';

export class LoadTeamById implements Action {
  readonly type = LOAD_TEAM_BY_ID;

  constructor(public payload: string) {}
}

export class LoadTeamByIdSuccess implements Action {
  readonly type = LOAD_TEAM_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadTeamByIdFailure implements Action {
  readonly type = LOAD_TEAM_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export const LOAD_MEMBERS_BY_TEAM_ID = 'LOAD_MEMBERS_BY_TEAM_ID';
export const LOAD_MEMBERS_BY_TEAM_ID_SUCCESS = 'LOAD_MEMBERS_BY_TEAM_ID_SUCCESS';
export const LOAD_MEMBERS_BY_TEAM_ID_FAILURE = 'LOAD_MEMBERS_BY_TEAM_ID_FAILURE';

export class LoadMembersByTeamId implements Action {
  readonly type = LOAD_MEMBERS_BY_TEAM_ID;

  constructor(public payload: string) {}
}

export class LoadMembersByTeamIdSuccess implements Action {
  readonly type = LOAD_MEMBERS_BY_TEAM_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadMembersByTeamIdFailure implements Action {
  readonly type = LOAD_MEMBERS_BY_TEAM_ID_FAILURE;

  constructor(public payload: string) {}
}

export const ADD_TEAM_MEMBERS = 'ADD_TEAM_MEMBERS';
export const ADD_TEAM_MEMBERS_SUCCESS = 'ADD_TEAM_MEMBERS_SUCCESS';
export const ADD_TEAM_MEMBERS_FAILURE = 'ADD_TEAM_MEMBERS_FAILURE';

export class AddTeamMembers implements Action {
  readonly type = ADD_TEAM_MEMBERS;

  constructor(public payload: {teamId: string, userIds: string[]}) {}
}

export class AddTeamMembersSuccess implements Action {
  readonly type = ADD_TEAM_MEMBERS_SUCCESS;

  constructor(public payload: any) {}
}

export class AddTeamMembersFailure implements Action {
  readonly type = ADD_TEAM_MEMBERS_FAILURE;

  constructor(public payload: string) {}
}

export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER';
export const REMOVE_TEAM_MEMBER_SUCCESS = 'REMOVE_TEAM_MEMBER_SUCCESS';
export const REMOVE_TEAM_MEMBER_FAILURE = 'REMOVE_TEAM_MEMBER_FAILURE';

export class RemoveTeamMember implements Action {
  readonly type = REMOVE_TEAM_MEMBER;

  constructor(public payload: {teamId: string, userId: string}) {}
}

export class RemoveTeamMemberSuccess implements Action {
  readonly type = REMOVE_TEAM_MEMBER_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveTeamMemberFailure implements Action {
  readonly type = REMOVE_TEAM_MEMBER_FAILURE;

  constructor(public payload: string) {}
}

export const LOAD_ADMINS_BY_TEAM_ID = 'LOAD_ADMINS_BY_TEAM_ID';
export const LOAD_ADMINS_BY_TEAM_ID_SUCCESS = 'LOAD_ADMINS_BY_TEAM_ID_SUCCESS';
export const LOAD_ADMINS_BY_TEAM_ID_FAILURE = 'LOAD_ADMINS_BY_TEAM_ID_FAILURE';

export class LoadAdminsByTeamId implements Action {
  readonly type = LOAD_ADMINS_BY_TEAM_ID;

  constructor(public payload: string) {}
}

export class LoadAdminsByTeamIdSuccess implements Action {
  readonly type = LOAD_ADMINS_BY_TEAM_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadAdminsByTeamIdFailure implements Action {
  readonly type = LOAD_ADMINS_BY_TEAM_ID_FAILURE;

  constructor(public payload: string) {}
}

export const ADD_TEAM_ADMINS = 'ADD_TEAM_ADMINS';
export const ADD_TEAM_ADMINS_SUCCESS = 'ADD_TEAM_ADMINS_SUCCESS';
export const ADD_TEAM_ADMINS_FAILURE = 'ADD_TEAM_ADMINS_FAILURE';

export class AddTeamAdmins implements Action {
  readonly type = ADD_TEAM_ADMINS;

  constructor(public payload: {teamId: string, userIds: string[]}) {}
}

export class AddTeamAdminsSuccess implements Action {
  readonly type = ADD_TEAM_ADMINS_SUCCESS;

  constructor(public payload: any) {}
}

export class AddTeamAdminsFailure implements Action {
  readonly type = ADD_TEAM_ADMINS_FAILURE;

  constructor(public payload: string) {}
}

export const REMOVE_TEAM_ADMIN = 'REMOVE_TEAM_ADMIN';
export const REMOVE_TEAM_ADMIN_SUCCESS = 'REMOVE_TEAM_ADMIN_SUCCESS';
export const REMOVE_TEAM_ADMIN_FAILURE = 'REMOVE_TEAM_ADMIN_FAILURE';

export class RemoveTeamAdmin implements Action {
  readonly type = REMOVE_TEAM_ADMIN;

  constructor(public payload: {teamId: string, userId: string}) {}
}

export class RemoveTeamAdminSuccess implements Action {
  readonly type = REMOVE_TEAM_ADMIN_SUCCESS;

  constructor(public payload: any) {}
}

export class RemoveTeamAdminFailure implements Action {
  readonly type = REMOVE_TEAM_ADMIN_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadTeams | LoadTeamsSuccess | LoadTeamsFailure | LoadMoreTeams | LoadMoreTeamsSuccess | LoadMoreTeamsFailure |
  LoadTeamByUser | LoadTeamByUserSuccess | LoadTeamByUserFailure | DeleteTeam | DeleteTeamSuccess | DeleteTeamFailure | CreateTeam |
  CreateTeamSuccess | CreateTeamFailure | UpdateTeam | UpdateTeamSuccess | UpdateTeamFailure | LoadTeamById | LoadTeamByIdSuccess |
  LoadTeamByIdFailure | LoadMembersByTeamId | LoadMembersByTeamIdSuccess | LoadMembersByTeamIdFailure | AddTeamMembers |
  AddTeamMembersSuccess | AddTeamMembersFailure | RemoveTeamMember | RemoveTeamMemberSuccess | RemoveTeamMemberFailure |
  LoadAdminsByTeamId | LoadAdminsByTeamIdSuccess | LoadAdminsByTeamIdFailure | AddTeamAdmins | AddTeamAdminsSuccess |
  AddTeamAdminsFailure | RemoveTeamAdmin | RemoveTeamAdminSuccess | RemoveTeamAdminFailure;

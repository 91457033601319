import * as UiActions from './ui.actions';

type Action = UiActions.All;

export interface UiState {
  showDropDown: boolean;
  showCreateRelationForm: boolean;
  showAddUsersModal: boolean;
  showAddToGroupModal: boolean;
  showAddToInstitutionModal: boolean;
  showCreateManagerModal: boolean;
}

const initialState: UiState = {
  showDropDown: false,
  showCreateRelationForm: false,
  showAddUsersModal: false,
  showAddToGroupModal: false,
  showAddToInstitutionModal: false,
  showCreateManagerModal: false,
};

export function reducer(state: UiState = initialState, {type, payload}: Action): UiState {
  switch (type) {
    case UiActions.TOGGLE_NAV_BAR_MENU_DROP_DOWN:
      return Object.assign({}, state, {
        showDropDown: (!state.showDropDown)
      });
    case UiActions.SET_NAV_BAR_MENU_DROP_DOWN_OPEN:
      return {
        ...state,
        showDropDown: payload,
      };
    case UiActions.TOGGLE_SHOW_CREATE_RELATION_FORM:
      return Object.assign({}, state,{
        showCreateRelationForm: (!state.showCreateRelationForm)
      });
    case UiActions.TOGGLE_SHOW_ADD_USERS_MODAL:
      return Object.assign({}, state, {
        showAddUsersModal: (!state.showAddUsersModal),
      });
    case UiActions.TOGGLE_SHOW_ADD_TO_GROUP_MODAL:
      return Object.assign({}, state, {
        showAddToGroupModal: (!state.showAddToGroupModal),
      });
    case UiActions.TOGGLE_SHOW_ADD_TO_INSTITUTION_MODAL:
      return Object.assign({}, state, {
        showAddToInstitutionModal: (!state.showAddToInstitutionModal),
      });
    case UiActions.TOGGLE_SHOW_CREATE_MANAGER_MODAL:
      return Object.assign({}, state, {
        showCreateManagerModal: (!state.showCreateManagerModal),
      });
    default:
      return state;
  }
}

export const getShowDropDown = (state: UiState) => state.showDropDown;
export const getShowCreateRelationForm = (state: UiState) => state.showCreateRelationForm;
export const getShowAddUsersModal = (state: UiState) => state.showAddUsersModal;
export const getShowAddToGroupModal = (state: UiState) => state.showAddToGroupModal;
export const getShowAddToInstitutionModal = (state: UiState) => state.showAddToInstitutionModal;
export const getShowCreateManagerModal = (state: UiState) => state.showCreateManagerModal;

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {forkJoin, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Address, Institution, SelectedEntity} from '../interfaces';

@Injectable()
export class InstitutionService extends ApiService {

  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  load(institutionId?: string): Observable<any> {
    let url = `${environment['API_URL']}${environment['API_VERSION']}institutions/hierarchy`;

    if (institutionId) {
      url += `/${institutionId}`;
    }

    return this._http.get(url);
  }

  loadMore(url: string): Observable<any> {
    return this._http.get(url);
  }

  loadById(institutionId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}`;
    return this._http.get(url);
  }

  createInstitution(institution: Institution, address: Address): Observable<any> {
    if (!institution.address_id || institution.address_id === null) {
      const createAddressUrl = `${environment['API_URL']}${environment['API_VERSION']}addresses`;

      return this._http.post(createAddressUrl, address)
        .pipe(
          switchMap((a: any) => {
            const createInstitutionUrl = `${environment['API_URL']}${environment['API_VERSION']}institutions`;
            institution = Object.assign({}, institution, {address_id: a.id});
            return this._http.post(createInstitutionUrl, institution);
          })
        );
    } else {
      const updateInstitutionUrl = `${environment['API_URL']}${environment['API_VERSION']}institutions`;
      return this._http.post(updateInstitutionUrl, institution);
    }
  }

  searchInstitutions(name: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/search?name=${name}`;
    return this._http.get(url);
  }

  updateInstitution(institution: Institution, address: Address): Observable<any> {
    if (address.street !== null && !address.id) {
      const createAddressUrl = `${environment['API_URL']}${environment['API_VERSION']}addresses`;

      return this._http.post(createAddressUrl, address)
        .pipe(
          switchMap((a: any) => {
            const updateInstitutionUrl = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institution.id}`;
            institution = Object.assign({}, institution, {address_id: a.id});
            return this._http.patch(updateInstitutionUrl, institution);
          })
        );
    } else {
      const updateAddressUrl = `${environment['API_URL']}${environment['API_VERSION']}addresses/${address.id}`;

      return this._http.patch(updateAddressUrl, address)
        .pipe(
          switchMap(() => {
            const updateInstitutionUrl = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institution.id}`;
            return this._http.patch(updateInstitutionUrl, institution);
          })
        );
    }
  }

  loadServiceReceivers(institutionId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/service-receivers-details`;

    return this._http.get(url)
      .pipe(
        map((result: any) => ({institution_id: institutionId, serviceReceivers: result}))
      );
  }

  addServiceReceivers(institutionId: string, userIds: string[]): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/service-receivers`;

    return this._http.post(url, {user_ids: userIds})
      .pipe(
        map((result: any) => ({institution_id: institutionId, serviceReceivers: result.results}))
      );
  }

  removeServiceReceiver(institutionId: string, userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/service-receivers/${userId}`;

    return this._http.delete(url)
      .pipe(
        map((result: any) => ({institution_id: institutionId, serviceReceivers: result.results}))
      );
  }

  loadEmployees(institutionId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/employees`;

    return this._http.get(url)
      .pipe(
        map((result: any) => ({institution_id: institutionId, employees: result.results}))
      );
  }

  addEmployees(institutionId: string, userIds: string[]): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/employees`;

    return this._http.post(url, {user_ids: userIds})
      .pipe(
        map((result: any) => ({institution_id: institutionId, employees: result.results}))
      );
  }

  removeEmployee(institutionId: string, userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}institutions/${institutionId}/employees/${userId}`;

    return this._http.delete(url)
      .pipe(
        map((result: any) => ({institution_id: institutionId, employees: result.results}))
      );
  }

  loadUserInstitutions(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}/institutions`;
    return this._http.get(url)
      .pipe(
        map(institutions => Object.assign({}, {
          user_id: userId,
          institutions: institutions
        }))
      );
  }

  addUserToInstitutions(userId: string, selected: SelectedEntity[]): Observable<any> {
    const observables = selected.map((s: SelectedEntity) => {
      if (s.relation_type === 'MEMBER') {
        return this.addServiceReceivers(s.id, [userId]);
      } else if (s.relation_type === 'EMPLOYED_AT') {
        return this.addEmployees(s.id, [userId]);
      }
    });

    return forkJoin(observables);
  }
}

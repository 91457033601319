import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {BillingService} from '../../core/billing.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as BillingActions from './billing.actions';

@Injectable()
export class BillingEffects {

  @Effect() loadBillingResponsibilities$: Observable<Action> = this._actions$.pipe(
    ofType(BillingActions.LOAD_BILLING_RESPONSIBILITIES_BY_ID),
    map((action: BillingActions.LoadBillingResponsibilitiesById) => action.payload),
    switchMap((payload) =>
      this._service.loadBillingResponsibilities(payload)
        .pipe(
          map(data => new BillingActions.LoadBillingResponsibilitiesByIdSuccess(data)),
          catchError(error => of(new BillingActions.LoadBillingResponsibilitiesByIdFailure(error)))
        )
    ),
  );

  @Effect() createBulkBillingInfo$: Observable<Action> = this._actions$.pipe(
    ofType(BillingActions.CREATE_BULK_BILLING_INFO),
    map((action: BillingActions.CreateBulkBillingInfo) => action.payload),
    switchMap((payload) =>
      this._service.createBulkBillingInfo(payload.info, payload.userIds)
        .pipe(
          map(data => new BillingActions.CreateBulkBillingInfoSuccess(data)),
          catchError(error => of(new BillingActions.CreateBulkBillingInfoFailure(error)))
        )
    ),
  );

  constructor(private _actions$: Actions, private _service: BillingService) {}
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as StatisticsActions from './statistics.actions';
import {StatisticsService} from '../../core/statistics.service';

@Injectable()
export class StatisticsEffects {
  @Effect() userStats$: Observable<Action> = this.actions$.pipe(
    ofType(StatisticsActions.LOAD_USER_STATISTICS),
    switchMap(_ => {
      return this._service.getUserStatistics()
        .pipe(
          map(state => new StatisticsActions.LoadUserStatisticsSuccess(state)),
          catchError(error => of(new StatisticsActions.LoadUserStatisticsFailure(error)))
        );
    })
  );

  @Effect() institutionStats$: Observable<Action> = this.actions$.pipe(
    ofType(StatisticsActions.LOAD_INSTITUTION_STATISTICS),
    switchMap(_ => {
      return this._service.getInstitutionStatistics()
        .pipe(
          map(state => new StatisticsActions.LoadInstitutionStatisticsSuccess(state)),
          catchError(error => of(new StatisticsActions.LoadInstitutionStatisticsFailure(error)))
        );
    })
  );

  @Effect() loadServiceReceiverList$: Observable<Action> = this.actions$.pipe(
    ofType(StatisticsActions.LOAD_SERVICE_RECEIVER_LIST),
    map((action: StatisticsActions.LoadServiceReceiverList) => action.payload),
    switchMap(payload => {
      return this._service.loadServiceReceiverList(payload)
        .pipe(
          map(state => new StatisticsActions.LoadServiceReceiverListSuccess(state)),
          catchError(error => of(new StatisticsActions.LoadServiceReceiverListFailure(error)))
        );
    })
  );

  @Effect() loadActiveUsers$: Observable<Action> = this.actions$.pipe(
    ofType(StatisticsActions.LOAD_USER_ACTIVITY),
    map((action: StatisticsActions.LoadUserActivity) => action.payload),
    switchMap(payload => {
      return this._service.loadActiveUsers(payload)
        .pipe(
          map(state => new StatisticsActions.LoadUserActivitySuccess(state)),
          catchError(error => of(new StatisticsActions.LoadUserActivityFailure(error)))
        );
    })
  );

  @Effect() loadMoreActiveUsers$: Observable<Action> = this.actions$.pipe(
    ofType(StatisticsActions.LOAD_MORE_USER_ACTIVITY),
    map((action: StatisticsActions.LoadMoreUserActivity) => action.payload),
    switchMap(payload => {
      return this._service.loadMoreActivity(payload)
        .pipe(
          map(state => new StatisticsActions.LoadMoreUserActivitySuccess(state)),
          catchError(error => of(new StatisticsActions.LoadMoreUserActivityFailure(error)))
        );
    })
  );

  @Effect() loadBillableServiceReceivers$: Observable<Action> = this.actions$.pipe(
    ofType(StatisticsActions.LOAD_BILLABLE_SERVICE_RECEIVERS),
    map((action: StatisticsActions.LoadBillableServiceReceivers) => action.payload),
    switchMap(payload => {
      return this._service.loadBillableServiceReceivers(payload)
        .pipe(
          map(state => new StatisticsActions.LoadBillableServiceReceiversSuccess(state)),
          catchError(error => of(new StatisticsActions.LoadBillableServiceReceiversFailure(error)))
        );
    })
  );

  @Effect() loadMoreBillableServiceReceivers$: Observable<Action> = this.actions$.pipe(
    ofType(StatisticsActions.LOAD_MORE_BILLABLE_SERVICE_RECEIVERS),
    map((action: StatisticsActions.LoadMoreBillableServiceReceivers) => action.payload),
    switchMap(payload => {
      return this._service.loadMore(payload)
        .pipe(
          map(state => new StatisticsActions.LoadMoreBillableServiceReceiversSuccess(state)),
          catchError(error => of(new StatisticsActions.LoadMoreBillableServiceReceiversFailure(error)))
        );
    })
  );

  constructor(private actions$: Actions, private _service: StatisticsService) {}
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UserService} from '../../core/user.service';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import * as UserActions from './user.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {ToastError, ToastSuccess} from '../toast/toast.actions';
import * as TeamActions from '../team/team.actions';

@Injectable()
export class UserEffects {

  @Effect() loadUsers$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.LOAD_USERS),
    switchMap((_) => {
      return this._service.loadUsers()
        .pipe(
          map(data => new UserActions.LoadUsersSuccess(data)),
          catchError(error => of(new UserActions.LoadUsersFailure(error)))
        );
    }),
  );

  @Effect() loadMoreUsers$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.LOAD_MORE_USERS),
    map((action: UserActions.LoadMoreUsers) => action.payload),
    switchMap((payload) => {
      return this._service.loadMore(payload)
        .pipe(
          map(data => new UserActions.LoadMoreUsersSuccess(data)),
          catchError(error => of(new UserActions.LoadMoreUsersFailure(error)))
        );
    }),
  );

  @Effect() searchUsers$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.SEARCH_USER),
    map((action: UserActions.SearchUser) => action.payload),
    switchMap((payload) => {
      return this._service.searchUsers(payload)
        .pipe(
          map(data => new UserActions.SearchUserSuccess(data)),
          catchError(error => of(new UserActions.SearchUserFailure(error)))
        );
    }),
  );

  @Effect() searchUserList$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.SEARCH_USER_LIST),
    map((action: UserActions.SearchUserList) => action.payload),
    switchMap((payload) => {
      return this._service.searchUsers(payload)
        .pipe(
          map(data => new UserActions.SearchUserListSuccess(data)),
          catchError(error => of(new UserActions.SearchUserListFailure(error)))
        );
    }),
  );

  @Effect() loadUserById$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.LOAD_USER_BY_ID),
    map((action: UserActions.LoadUserById) => action.payload),
    switchMap((payload) => {
      return this._service.loadUserById(payload)
        .pipe(
          map(data => new UserActions.LoadUserByIdSuccess(data)),
          catchError(error => of(new UserActions.LoadUserByIdFailure(error)))
        );
    }),
  );

  @Effect() updateUser$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.UPDATE_USER),
    map((action: UserActions.UpdateUser) => action.payload),
    switchMap((payload) => {
      return this._service.updateUser(payload)
        .pipe(
          map(data => new UserActions.UpdateUserSuccess(data)),
          map(_ => new ToastSuccess('User was updated!')),
          catchError(error => of(new UserActions.UpdateUserFailure(error)))
        );
    }),
  );

  @Effect() updateUserProfile$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.UPDATE_USER_PROFILE),
    map((action: UserActions.UpdateUserProfile) => action.payload),
    switchMap((payload) => {
      return this._service.updateUserProfile(payload.userId, payload.userprofile)
        .pipe(
          map(data => new UserActions.UpdateUserProfileSuccess(data)),
          map(_ => new ToastSuccess('User was updated!')),
          catchError(error => of(new UserActions.UpdateUserProfileFailure(error)))
        );
    }),
  );

  @Effect() setPassword$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.SET_PASSWORD),
    map((action: UserActions.SetPassword) => action.payload),
    switchMap((payload) => {
      return this._service.setPassword(payload.userId, payload.password)
        .pipe(
          map(data => new UserActions.SetPasswordSuccess(data)),
          map(_ => new ToastSuccess('Password was updated!')),
          catchError(error => of(new UserActions.SetPasswordFailure(error)))
        );
    }),
  );

  @Effect() unConfirmUser$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.UNCONFIRM_USER),
    map((action: UserActions.UnConfirmUser) => action.payload),
    switchMap((payload) => {
      return this._service.unConfirmUser(payload)
        .pipe(
          map(data => new UserActions.UnConfirmUserSuccess(data)),
          catchError(error => of(new UserActions.UnConfirmUserFailure(error)))
        );
    }),
  );

  @Effect() unConfirmUserSuccess1$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.UNCONFIRM_USER),
    map(_ => new ToastSuccess('User was unconfirmed!')),
  );

  @Effect() unConfirmUserSuccess2$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.UNCONFIRM_USER_SUCCESS),
    map((action: UserActions.UnConfirmUserSuccess) => action.payload),
    switchMap((payload) => {
      return this._service.loadUserById(payload.user_id)
        .pipe(
          map(data => new UserActions.LoadUserByIdSuccess(data)),
          catchError(error => of(new UserActions.LoadUserByIdFailure(error)))
        );
    }),
  );

  @Effect() loadPaymentInfo$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.LOAD_PAYMENT_INFO),
    map((action: UserActions.LoadPaymentInfo) => action.payload),
    switchMap((payload) => {
      return this._service.loadPaymentInfo(payload)
        .pipe(
          map(data => new UserActions.LoadPaymentInfoSuccess(data)),
          catchError(error => of(new UserActions.LoadPaymentInfoFailure(error)))
        );
    }),
  );

  @Effect() updateBillingInfo$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.UPDATE_BILLING_INFO),
    map((action: UserActions.UpdateBillingInfo) => action.payload),
    switchMap((payload) => {
      return this._service.updateBillingInfo(payload)
        .pipe(
          map(data => new UserActions.UpdateBillingInfoSuccess(data)),
          catchError(error => of(new UserActions.UpdateBillingInfoFailure(error)))
        );
    }),
  );

  @Effect() createBillingInfo$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.CREATE_BILLING_INFO),
    map((action: UserActions.CreateBillingInfo) => action.payload),
    switchMap((payload) => {
      return this._service.createBillingInfo(payload)
        .pipe(
          map(data => new UserActions.CreateBillingInfoSuccess(data)),
          catchError(error => of(new UserActions.CreateBillingInfoFailure(error)))
        );
    }),
  );

  @Effect() loadUserActivity$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.LOAD_USER_ACTIVITY_BY_ID),
    map((action: UserActions.LoadUserActivityById) => action.payload),
    switchMap((payload) => {
      return this._service.loadActivity(payload)
        .pipe(
          map(data => new UserActions.LoadUserActivityByIdSuccess(data)),
          catchError(error => of(new UserActions.LoadUserActivityByIdFailure(error)))
        );
    }),
  );

  @Effect() deleteUser$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.DELETE_USER),
    map((action: UserActions.DeleteUser) => action.payload),
    switchMap((payload) => {
      return this._service.deleteUser(payload)
        .pipe(
          map(data => new UserActions.DeleteUserSuccess(data)),
          catchError(error => of(new UserActions.DeleteUserFailure(error)))
        );
    }),
  );

  @Effect() deleteUserSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.DELETE_USER_SUCCESS),
    map((action: UserActions.DeleteUser) => action.payload),
    map((_) => new ToastSuccess('User deleted!')),
  );

  @Effect() deleteUserFailure$: Observable<Action> = this._actions$.pipe(
    ofType(UserActions.DELETE_USER_FAILURE),
    map((action: UserActions.DeleteUserFailure) => action.payload),
    map((response: any) => new ToastError(response.error.message)),
  );

  constructor(private _actions$: Actions, private _service: UserService) {}
}

import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {UnAuth} from './store/auth/auth.actions';
import {IAppState} from './store';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private store: Store<IAppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status === 401) {
            this.store.dispatch(new UnAuth());
          }

          return throwError(response);
        })
      );
  }
}

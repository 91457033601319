import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as TeamActions from './team.actions';
import {UserService} from '../../core/user.service';
import {TeamService} from '../../core/team.service';
import {ToastError, ToastSuccess} from '../toast/toast.actions';

@Injectable()
export class TeamEffects {

  @Effect() loadTeams$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.LOAD_TEAMS),
    switchMap(() =>
      this._service.load()
        .pipe(
          map(data => new TeamActions.LoadTeamsSuccess(data)),
          catchError(error => of(new TeamActions.LoadTeamsFailure(error)))
        )
    ),
  );

  @Effect() loadMoreTeams$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.LOAD_MORE_TEAMS),
    map((action: TeamActions.LoadMoreTeams) => action.payload),
    switchMap((payload) =>
      this._service.loadMore(payload)
        .pipe(
          map(data => new TeamActions.LoadMoreTeamsSuccess(data)),
          catchError(error => of(new TeamActions.LoadMoreTeamsFailure(error)))
        )
    ),
  );

  @Effect() loadTeamsByUser$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.LOAD_TEAM_BY_USER),
    map((action: TeamActions.LoadTeamByUser) => action.payload),
    switchMap((payload) =>
      this._userService.loadTeam(payload)
        .pipe(
          map(data => new TeamActions.LoadTeamByUserSuccess(data)),
          catchError(error => of(new TeamActions.LoadTeamByUserFailure(error)))
        )
    ),
  );

  @Effect() createTeam$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.CREATE_TEAM),
    map((action: TeamActions.CreateTeam) => action.payload),
    switchMap((payload) =>
      this._service.createTeam(payload.service_receiver_id, payload.name, payload.description, payload.admins)
        .pipe(
          map(data => new TeamActions.CreateTeamSuccess(data)),
          catchError(error => of(new TeamActions.CreateTeamFailure(error)))
        )
    ),
  );

  @Effect() createTeamSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.CREATE_TEAM_SUCCESS),
    map((_) => new ToastSuccess('Team created!')),
  );

  @Effect() createTeamFailure$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.CREATE_TEAM_FAILURE),
    map((_) => new ToastError('Failed creating team!')),
  );

  @Effect() updateTeam$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.UPDATE_TEAM),
    map((action: TeamActions.UpdateTeam) => action.payload),
    switchMap((payload) =>
      this._service.updateTeam(payload.id, payload.name, payload.description)
        .pipe(
          map(data => new TeamActions.UpdateTeamSuccess(data)),
          catchError(error => of(new TeamActions.UpdateTeamFailure(error)))
        )
    ),
  );

  @Effect() updateTeamSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.UPDATE_TEAM_SUCCESS),
    map((_) => new ToastSuccess('Team updated!')),
  );

  @Effect() updateTeamFailure$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.UPDATE_TEAM_FAILURE),
    map((_) => new ToastError('Failed updating team!')),
  );

  @Effect() deleteTeam$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.DELETE_TEAM),
    map((action: TeamActions.DeleteTeam) => action.payload),
    switchMap((payload) =>
      this._service.deleteTeam(payload.userId, payload.teamId)
        .pipe(
          map(data => new TeamActions.DeleteTeamSuccess(data)),
          catchError(error => of(new TeamActions.DeleteTeamFailure(error)))
        )
    ),
  );

  @Effect() deleteTeamSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.DELETE_TEAM_SUCCESS),
    map((_) => new ToastSuccess('Team deleted!')),
  );

  @Effect() deleteTeamFailure$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.DELETE_TEAM_FAILURE),
    map((_) => new ToastError('Failed deleting team!')),
  );

  @Effect() loadTeamById$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.LOAD_TEAM_BY_ID),
    map((action: TeamActions.LoadTeamById) => action.payload),
    switchMap((payload) =>
      this._service.loadTeamById(payload)
        .pipe(
          map(data => new TeamActions.LoadTeamByIdSuccess(data)),
          catchError(error => of(new TeamActions.LoadTeamByIdFailure(error)))
        )
    ),
  );

  @Effect() loadMembersByTeamId$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.LOAD_MEMBERS_BY_TEAM_ID),
    map((action: TeamActions.LoadMembersByTeamId) => action.payload),
    switchMap((payload) =>
      this._service.loadMembers(payload)
        .pipe(
          map(data => new TeamActions.LoadMembersByTeamIdSuccess(data)),
          catchError(error => of(new TeamActions.LoadMembersByTeamIdFailure(error)))
        )
    ),
  );

  @Effect() addTeamMembers$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.ADD_TEAM_MEMBERS),
    map((action: TeamActions.AddTeamMembers) => action.payload),
    switchMap((payload) =>
      this._service.addMembers(payload.teamId, payload.userIds)
        .pipe(
          map(data => new TeamActions.AddTeamMembersSuccess(data)),
          catchError(error => of(new TeamActions.AddTeamMembersFailure(error)))
        )
    ),
  );

  @Effect() removeTeamMember$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.REMOVE_TEAM_MEMBER),
    map((action: TeamActions.RemoveTeamMember) => action.payload),
    switchMap((payload) =>
      this._service.removeMember(payload.teamId, payload.userId)
        .pipe(
          map(data => new TeamActions.RemoveTeamMemberSuccess(data)),
          catchError(error => of(new TeamActions.RemoveTeamMemberFailure(error)))
        )
    ),
  );

  @Effect() loadAdminsByTeamId$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.LOAD_ADMINS_BY_TEAM_ID),
    map((action: TeamActions.LoadAdminsByTeamId) => action.payload),
    switchMap((payload) =>
      this._service.loadAdmins(payload)
        .pipe(
          map(data => new TeamActions.LoadAdminsByTeamIdSuccess(data)),
          catchError(error => of(new TeamActions.LoadAdminsByTeamIdFailure(error)))
        )
    ),
  );

  @Effect() addTeamAdmins$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.ADD_TEAM_ADMINS),
    map((action: TeamActions.AddTeamAdmins) => action.payload),
    switchMap((payload) =>
      this._service.addAdmins(payload.teamId, payload.userIds)
        .pipe(
          map(data => new TeamActions.AddTeamAdminsSuccess(data)),
          catchError(error => of(new TeamActions.AddTeamAdminsFailure(error)))
        )
    ),
  );

  @Effect() removeTeamAdmin$: Observable<Action> = this._actions$.pipe(
    ofType(TeamActions.REMOVE_TEAM_ADMIN),
    map((action: TeamActions.RemoveTeamAdmin) => action.payload),
    switchMap((payload) =>
      this._service.removeAdmin(payload.teamId, payload.userId)
        .pipe(
          map(data => new TeamActions.RemoveTeamAdminSuccess(data)),
          catchError(error => of(new TeamActions.RemoveTeamAdminFailure(error)))
        )
    ),
  );

  constructor(private _actions$: Actions, private _userService: UserService, private _service: TeamService) {}
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {AuthenticationState} from '../enum';
import * as moment from 'moment';
import * as jwtDecode from 'jwt-decode';
import {map, shareReplay, switchMap, take} from 'rxjs/operators';

const USER_ID = environment['TOKEN'];

@Injectable()
export class AuthService {

  constructor(private _http: HttpClient, private cookie: CookieService, private _ls: LocalStorage) {}

  checkAuthentication(): Observable<any> {
    return this._ls.getItem(USER_ID)
      .pipe(
        map((token: string) => {
          if (token) {
            return AuthenticationState.AUTHENTICATED;
          } else {
            return AuthenticationState.UNAUTHENTICATED;
          }
        })
      );
  }

  authenticate(user: {email: string, password: string}): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}auth/admin`;
    return this._http.post(url, user);
  }

  verifyMfa(userId: string, token: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}auth/verify-mfa-token`;
    return this._http.post(url, {user_id: userId, token: token})
      .pipe(
        switchMap((response: any) => this._ls.setItem(USER_ID, response.id))
      );
  }

  getAuthUser(): Observable<any> {
    return this._ls.getItem(USER_ID)
      .pipe(
        switchMap((userId: string) => {
          const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}`;
          const headers = new HttpHeaders({'Content-Type': 'application/json'});
          return this._http.get(url, {headers: headers});
        })
      );
  }

  unAuth(): Observable<any> {
    return this._http.get(`${environment['API_URL']}${environment['API_VERSION']}/auth/endsession-admin`)
      .pipe(
        shareReplay(),
        switchMap(() => this._ls.clear())
      );
  }

  private _tokenHasExpired(token: string) {
    if (token) {
      const decodedToken = jwtDecode(token);
      const now = moment().valueOf();
      return moment.unix(decodedToken.exp).valueOf() < now;
    } else {
      this._ls.clear()
        .pipe(
          take(1)
        )
        .subscribe();
    }
  }
}

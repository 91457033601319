import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Relation} from '../interfaces';

@Injectable()
export class UserService extends ApiService {

  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  loadUsers(limit = 10, offset = 0): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users?limit=${limit}&offset=${offset}`;
    return this._http.get(url);
  }

  loadMore(url: string): Observable<any> {
    return this._http.get(url);
  }

  searchUsers(term: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/search?term=${term}`;
    return this._http.get(url);
  }

  loadUserById(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}`;

    return this._http.get(url)
      .pipe(
        switchMap((user: any) => {
          const profileUrl = `${environment['API_URL']}${environment['API_VERSION']}users/${user.id}/profile`;
          return this._http.get(profileUrl)
            .pipe(
              map(profile => Object.assign({}, user, {userprofile: profile}))
            );
        })
      );
  }

  updateUser(user: any): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${user.id}`;
    return this._http.patch(url, user);
  }

  updateUserProfile(userId: string, userprofile: any): Observable<any> {
    const profile = Object.assign({}, userprofile);
    delete profile.image;

    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}/profile`;
    return this._http.patch(url, profile);
  }

  createUser(user: any): Observable<any> {
    user = {...user, cell_phone_number: user.userprofile.cell_phone_number};

    if (user.is_service_receiver) {
      user = {...user, consent_obtained: true};
    }

    const userprofile = Object.assign({}, user.userprofile);
    delete user.userprofile;

    const createUrl = `${environment['API_URL']}${environment['API_VERSION']}users`;

    return this._http.post(createUrl, user)
      .pipe(
        switchMap((newUser: any) => {
          if (userprofile.cell_phone_number) {
            return this.updateUserProfile(newUser.id, userprofile);
          } else {
            return of(newUser);
          }
        }),
      );
  }

  loadUserRelations(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}/relations`;
    return this._http.get(url)
      .pipe(
        map(relations => ({
          user_id: userId,
          results: relations
        }))
      );
  }

  createRelation(relation: Relation): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}relations`;
    return this._http.post(url, relation);
  }

  removeRelation(relationId: string, userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}relations/${relationId}`;
    return this._http.delete(url)
      .pipe(
        map(_ => ({
          relation_id: relationId,
          user_id: userId
        }))
      );
  }

  setPassword(userId: string, password: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}/password`;
    return this._http.patch(url, {password});
  }

  unConfirmUser(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}/unconfirm`;
    return this._http.post(url, {});
  }

  loadPaymentInfo(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}billing/user/${userId}`;
    return this._http.get(url)
      .pipe(
        map((paymentInfo: any[]) => ({userId, paymentInfo}))
      );
  }

  updateBillingInfo(billingInfo: any): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}billing/${billingInfo.id}`;
    return this._http.patch(url, billingInfo);
  }

  createBillingInfo(billingInfo: any): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}billing`;
    return this._http.post(url, billingInfo);
  }

  loadActivity(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}/activity`;
    return this._http.get(url)
      .pipe(
        map((results) => ({userId, results}))
      );
  }

  loadTeam(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}/teams`;
    return this._http.get(url)
      .pipe(
        map((response) => ({
          userId,
          response
        }))
      );
  }

  deleteUser(userId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}users/${userId}`;
    return this._http.delete(url).pipe(map(() => userId));
  }
}

import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';

@Component({
  template: `
    <div class="overlay">
      <div class="overlay__content">
        <h1 class="u-margin-bottom-l">{{ title }}</h1>
        <p>{{ text }}</p>
        <div class="flex flex--gap u-margin-top-l">
          <div class="flex__2"></div>
          <div class="flex__1">
            <button class="button button--danger button--fullwidth" (click)="selectOption(false)">{{ cancelButtonText }}</button>
          </div>
          <div class="flex__1">
            <button class="button button--primary button--fullwidth" (click)="selectOption(true)">{{ confirmButtonText }}</button>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ConfirmModalComponent {
  @ViewChild('confirmButton') confirmButton: ElementRef;
  @Input() title: string;
  @Input() text: string;
  @Input() html: string;
  @Input() confirmButtonText: string;
  @Input() cancelButtonText: string;

  @Output() onButtonClicked: EventEmitter<boolean> = new EventEmitter();

  selectOption(result: boolean): void {
    this.onButtonClicked.emit(result);
  }

  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.code === 'Escape' || event.keyCode === 27) {
      this.onButtonClicked.emit(false);
    }

    if (event.key === 'Enter' || event.code === 'Enter' || event.keyCode === 13) {
      this.onButtonClicked.emit(true);
    }
  }
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {InstitutionService} from '../../core/institution.service';
import {Action} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import * as InstitutionActions from './institution.actions';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Institution} from '../../interfaces';
import {ToastError, ToastSuccess} from '../toast/toast.actions';
import {LoadInstitutionsByUser} from './institution.actions';

@Injectable()
export class InstitutionEffects {

  @Effect() loadInstitutionHierarchy$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.LOAD_INSTITUTION_HIERARCHY),
    switchMap((_) => {
      return this._service.load()
        .pipe(
          map(data => new InstitutionActions.LoadInstitutionHierarchySuccess(data)),
          catchError(error => of(new InstitutionActions.LoadInstitutionHierarchyFailure(error)))
        );
    }),
  );

  @Effect() loadInstitutionHierarchyById$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.LOAD_INSTITUTION_HIERARCHY_BY_ID),
    map((action: InstitutionActions.LoadInstitutionHierarchyById) => action.payload),
    switchMap((payload) => {
      return this._service.load(payload)
        .pipe(
          map(data => new InstitutionActions.LoadInstitutionHierarchyByIdSuccess(data)),
          catchError(error => of(new InstitutionActions.LoadInstitutionHierarchyByIdFailure(error)))
        );
    }),
  );

  @Effect() loadMoreInstitutions$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.LOAD_MORE_INSTITUTIONS),
    map((action: InstitutionActions.LoadMoreInstitutions) => action.payload),
    switchMap((payload) => {
      return this._service.loadMore(payload)
        .pipe(
          map(data => new InstitutionActions.LoadMoreInstitutionsSuccess(data)),
          catchError(error => of(new InstitutionActions.LoadMoreInstitutionsFailure(error)))
        );
    }),
  );

  @Effect() loadInstitutionById$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.LOAD_INSTITUTION_BY_ID),
    map((action: InstitutionActions.LoadInstitutionById) => action.payload),
    switchMap((payload) => {
      return this._service.loadById(payload)
        .pipe(
          map((data: Institution) => new InstitutionActions.LoadInstitutionByIdSuccess(data)),
          catchError(error => of(new InstitutionActions.LoadInstitutionByIdFailure(error)))
        );
    }),
  );

  @Effect() searchInstitutions$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.SEARCH_INSTITUTION),
    map((action: InstitutionActions.SearchInstitution) => action.payload),
    switchMap((payload) => {
      return this._service.searchInstitutions(payload)
        .pipe(
          map(data => new InstitutionActions.SearchInstitutionSuccess(data)),
          catchError(error => of(new InstitutionActions.SearchInstitutionFailure(error)))
        );
    }),
  );

  @Effect() updateInstitution$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.UPDATE_INSTITUTION),
    map((action: InstitutionActions.UpdateInstitution) => action.payload),
    switchMap((payload) => {
      return this._service.updateInstitution(payload.institution, payload.address)
        .pipe(
          map(data => new InstitutionActions.UpdateInstitutionSuccess(data)),
          catchError(error => of(new InstitutionActions.UpdateInstitutionFailure(error)))
        );
    }),
  );

  @Effect() loadInstitutionMembers$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.LOAD_INSTITUTION_SERVICE_RECEIVERS),
    map((action: InstitutionActions.LoadInstitutionServiceReceivers) => action.payload),
    switchMap((payload) => {
      return this._service.loadServiceReceivers(payload)
        .pipe(
          map(data => new InstitutionActions.LoadInstitutionServiceReceiversSuccess(data)),
          catchError(error => of(new InstitutionActions.LoadInstitutionServiceReceiversFailure(error)))
        );
    }),
  );

  @Effect() addInstitutionServiceReceivers$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.ADD_INSTITUTION_SERVICE_RECEIVERS),
    map((action: InstitutionActions.AddInstitutionServiceReceivers) => action.payload),
    switchMap((payload) => {
      return this._service.addServiceReceivers(payload.institutionId, payload.userIds)
        .pipe(
          map(data => new InstitutionActions.AddInstitutionServiceReceiversSuccess(data)),
          catchError(error => of(new InstitutionActions.AddInstitutionServiceReceiversFailure(error)))
        );
    }),
  );

  @Effect() addedInstitutionServiceReceiversSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.ADD_INSTITUTION_SERVICE_RECEIVERS_SUCCESS),
    map((_) => new ToastSuccess('Service Receivers added!')),
  );

  @Effect() addedInstitutionServiceReceiversFailure$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.ADD_INSTITUTION_SERVICE_RECEIVERS_FAILURE),
    map((_) => new ToastError('Service Receivers not added. Please try again.')),
  );

  @Effect() removeInstitutionServiceReceiver$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.REMOVE_INSTITUTION_SERVICE_RECEIVER),
    map((action: InstitutionActions.RemoveInstitutionServiceReceiver) => action.payload),
    switchMap((payload) => {
      return this._service.removeServiceReceiver(payload.institutionId, payload.userId)
        .pipe(
          switchMap(data => [
            new InstitutionActions.RemoveInstitutionServiceReceiverSuccess(data),
            new LoadInstitutionsByUser(payload.userId)
          ]),
          catchError(error => of(new InstitutionActions.RemoveInstitutionServiceReceiverFailure(error)))
        );
    }),
  );

  @Effect() removedInstitutionServiceReceiverSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.REMOVE_INSTITUTION_SERVICE_RECEIVER_SUCCESS),
    map((_) => new ToastSuccess('Service Receiver removed!')),
  );

  @Effect() removedInstitutionServiceReceiverFailure$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.REMOVE_INSTITUTION_SERVICE_RECEIVER_FAILURE),
    map((_) => new ToastError('Service Receiver not removed. Please try again.')),
  );

  @Effect() loadInstitutionEmployees$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.LOAD_INSTITUTION_EMPLOYEES),
    map((action: InstitutionActions.LoadInstitutionEmployees) => action.payload),
    switchMap((payload) => {
      return this._service.loadEmployees(payload)
        .pipe(
          map(data => new InstitutionActions.LoadInstitutionEmployeesSuccess(data)),
          catchError(error => of(new InstitutionActions.LoadInstitutionEmployeesFailure(error)))
        );
    }),
  );

  @Effect() addInstitutionEmployees$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.ADD_INSTITUTION_EMPLOYEES),
    map((action: InstitutionActions.AddInstitutionEmployees) => action.payload),
    switchMap((payload) => {
      return this._service.addEmployees(payload.institutionId, payload.userIds)
        .pipe(
          map(data => new InstitutionActions.AddInstitutionEmployeesSuccess(data)),
          catchError(error => of(new InstitutionActions.AddInstitutionEmployeesFailure(error)))
        );
    }),
  );

  @Effect() addedInstitutionEmployeesSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.ADD_INSTITUTION_EMPLOYEES_SUCCESS),
    map((_) => new ToastSuccess('Employees added!')),
  );

  @Effect() addedInstitutionEmployeesFailure$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.ADD_INSTITUTION_EMPLOYEES_FAILURE),
    map((_) => new ToastError('Employees not added. Please try again.')),
  );

  @Effect() removeInstitutionEmployee$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.REMOVE_INSTITUTION_EMPLOYEE),
    map((action: InstitutionActions.RemoveInstitutionEmployee) => action.payload),
    switchMap((payload) => {
      return this._service.removeEmployee(payload.institutionId, payload.userId)
        .pipe(
          map(data => new InstitutionActions.RemoveInstitutionEmployeeSuccess(data)),
          catchError(error => of(new InstitutionActions.RemoveInstitutionEmployeeFailure(error)))
        );
    }),
  );

  @Effect() removedInstitutionEmployeeSuccess$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.REMOVE_INSTITUTION_EMPLOYEE_SUCCESS),
    map((_) => new ToastSuccess('Employee removed!')),
  );

  @Effect() removedInstitutionEmployeeFailure$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.REMOVE_INSTITUTION_EMPLOYEES_FAILURE),
    map((_) => new ToastError('Employee not removed. Please try again.')),
  );

  @Effect() loadInstitutionsByUser$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.LOAD_INSTITUTIONS_BY_USER),
    map((action: InstitutionActions.LoadInstitutionsByUser) => action.payload),
    switchMap((payload) => {
      return this._service.loadUserInstitutions(payload)
        .pipe(
          map(data => new InstitutionActions.LoadInstitutionsByUserSuccess(data)),
          catchError(error => of(new InstitutionActions.LoadInstitutionsByUserFailure(error)))
        );
    }),
  );

  @Effect() addUserToGroups$: Observable<Action> = this._actions$.pipe(
    ofType(InstitutionActions.ADD_USER_TO_INSTITUTIONS),
    map((action: InstitutionActions.AddUserToInstitutions) => action.payload),
    switchMap((payload) =>
      this._service.addUserToInstitutions(payload.userId, payload.selected)
        .pipe(
          map(_ => new LoadInstitutionsByUser(payload.userId)),
          catchError(error => of(new InstitutionActions.AddUserToInstitutionsFailure(error)))
        )
    ),
  );

  constructor(private _actions$: Actions, private _service: InstitutionService) {}
}

import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable, of} from 'rxjs';
import {Action} from '@ngrx/store';
import {catchError, map, switchMap} from 'rxjs/operators';
import * as EulaActions from './eula.actions';
import {EulaService} from '../../core/eula.service';

@Injectable()
export class EulaEffects {
  @Effect() loadEulas$: Observable<Action> = this.actions$.pipe(
    ofType(EulaActions.LOAD_EULA_RESPONDERS),
    map((action: EulaActions.LoadEulaResponders) => action.payload),
    switchMap(payload => {
      return this._service.loadResponders(payload)
        .pipe(
          map(state => new EulaActions.LoadEulaRespondersSuccess(state)),
          catchError(error => of(new EulaActions.LoadEulaRespondersFailure(error)))
        );
    })
  );

  constructor(private actions$: Actions, private _service: EulaService) {}
}

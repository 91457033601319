import * as UserActions from './user.actions';
import {handleError} from '../helpers';
import {CrudOpState} from '../../enum';

type Action = UserActions.All;

export interface UserState {
  loading: boolean;
  entities: Array<any>;
  entitiesById: any;
  searchResults: Array<any>;
  listSearchResults: Array<any>;
  billingInfoByUser: any;
  next: string;
  error: string;
  activityByUserId: any;
  crudState: CrudOpState;
}

const initialState: UserState = {
  loading: false,
  entities: null,
  entitiesById: null,
  searchResults: null,
  listSearchResults: null,
  billingInfoByUser: null,
  next: null,
  error: null,
  activityByUserId: null,
  crudState: null,
};

export function reducer(state: UserState = initialState, {type, payload}: Action): UserState {
  switch (type) {
    case UserActions.LOAD_USERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.LOAD_USERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: payload.results,
        next: payload.next
      });
    case UserActions.LOAD_USERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.LOAD_MORE_USERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.LOAD_MORE_USERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: [...state.entities, ...payload.results],
        next: payload.next
      });
    case UserActions.LOAD_MORE_USERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.SEARCH_USER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.SEARCH_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        searchResults: payload.results,
        error: null
      });
    case UserActions.SEARCH_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.SEARCH_USER_LIST:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.SEARCH_USER_LIST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        listSearchResults: payload.results,
        error: null
      });
    case UserActions.SEARCH_USER_LIST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case UserActions.CLEAR_USER_SEARCH:
      return Object.assign({}, state, {
        loading: false,
        searchResults: null,
        error: null
      });
    case UserActions.LOAD_USER_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.LOAD_USER_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesById: Object.assign({}, state.entitiesById, {
          [payload.id]: payload
        }),
        error: null
      });
    case UserActions.LOAD_USER_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case UserActions.UPDATE_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: (payload.error) ? payload.error : payload,
      });
    case UserActions.SET_PASSWORD:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.SET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        loading: false
      });
    case UserActions.SET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: (payload.error) ? payload.error : payload,
      });
    case UserActions.UNCONFIRM_USER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.UNCONFIRM_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false
      });
    case UserActions.UNCONFIRM_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: (payload.error) ? payload.error : payload,
      });
    case UserActions.LOAD_PAYMENT_INFO:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.LOAD_PAYMENT_INFO_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        billingInfoByUser: Object.assign({}, state.billingInfoByUser, {
          [payload.userId]: payload.paymentInfo
        })
      });
    case UserActions.LOAD_PAYMENT_INFO_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: (payload.error) ? payload.error : payload,
      });
    case UserActions.UPDATE_BILLING_INFO:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.UPDATE_BILLING_INFO_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        billingInfoByUser: Object.assign({}, state.billingInfoByUser, {
          [payload.service_receiver.id]: state.billingInfoByUser[payload.service_receiver.id].map((entity) => {
            if (entity.id === payload.id) {
              return payload;
            } else {
              return entity;
            }
          })
        })
      });
    case UserActions.UPDATE_BILLING_INFO_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: (payload.error) ? payload.error : payload,
      });
    case UserActions.CREATE_BILLING_INFO:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.CREATE_BILLING_INFO_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        billingInfoByUser: Object.assign({}, state.billingInfoByUser, {
          [payload.service_receiver.id]: (state.billingInfoByUser[payload.service_receiver.id])
            ? [].concat(...state.billingInfoByUser[payload.service_receiver.id], payload)
            : [].concat(payload)
        })
      });
    case UserActions.CREATE_BILLING_INFO_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: (payload.error) ? payload.error : payload,
      });
    case UserActions.LOAD_USER_ACTIVITY_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case UserActions.LOAD_USER_ACTIVITY_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        activityByUserId: Object.assign({}, state.activityByUserId, {
          [payload.userId]: payload.results,
        })
      });
    case UserActions.LOAD_USER_ACTIVITY_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: (payload.error) ? payload.error : payload,
      });
    case UserActions.DELETE_USER:
      return Object.assign({}, state, {
        loading: true,
        crudState: CrudOpState.DELETING,
        error: null
      });
    case UserActions.DELETE_USER_SUCCESS:
      const removedUser = (state.entities) ? state.entities.filter((u) => u.id !== payload) : null;
      const sorted = Object.keys(state.entitiesById).reduce((object, id) => {
        if (id !== payload) {
          object[id] = state.entitiesById[id];
        }

        return object;
      }, {});

      return Object.assign({}, state, {
        loading: false,
        crudState: CrudOpState.DELETED,
        entities: removedUser,
        entitiesById: sorted,
      });
    case UserActions.DELETE_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        crudState: null,
        error: (payload.error) ? payload.error : payload,
      });
    case UserActions.SET_USER_CRUD_STATE:
      return Object.assign({}, state, {
        crudState: payload,
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: UserState) => state.loading;
export const getEntities = (state: UserState) => state.entities;
export const getSearchResults = (state: UserState) => state.searchResults;
export const getListSearchResults = (state: UserState) => state.listSearchResults;
export const getEntitiesById = (state: UserState) => state.entitiesById;
export const getError = (state: UserState) => state.error;
export const getNext = (state: UserState) => state.next;
export const getBillingInfoByUser = (state: UserState) => state.billingInfoByUser;
export const getActivityByUserId = (state: UserState) => state.activityByUserId;
export const getCrudState = (state: UserState) => state.crudState;

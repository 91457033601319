import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Address} from '../interfaces';

@Injectable()
export class AddressService extends ApiService {
  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  searchAddress(address: Address): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}addresses/search?street=${address.street}`;
    return this._http.get(url);
  }

  loadById(addressId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}addresses/${addressId}`;
    return this._http.get(url);
  }

  loadCountries(): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}addresses/countries`;
    return this._http.get(url);
  }
}

import * as InstitutionActions from './institution.actions';
import {handleError} from '../helpers';

type Action = InstitutionActions.All;

export interface InstitutionState {
  loading: boolean;
  loadingChildById: string;
  entities: Array<any>;
  entitiesById: any;
  hierarchyById: any;
  searchResults: Array<any>;
  serviceReceivers: any;
  institutionsByUser: any;
  employees: any;
  next: string;
  error: string;
}

const initialState: InstitutionState = {
  loading: false,
  loadingChildById: null,
  entities: null,
  entitiesById: null,
  hierarchyById: null,
  searchResults: null,
  serviceReceivers: null,
  employees: null,
  institutionsByUser: null,
  next: null,
  error: null
};

function sortById(state, payload) {
  if (!state) {
    state = {};
  }

  payload.map((newEntity) => {
    state = {
      ...state,
      [newEntity.id]: newEntity
    };
  });

  return state;
}

export function reducer(state: InstitutionState = initialState, {type, payload}: Action): InstitutionState {
  switch (type) {
    case InstitutionActions.LOAD_INSTITUTION_HIERARCHY:
      return {
        ...state,
        loading: true,
        error: null
      };
    case InstitutionActions.LOAD_INSTITUTION_HIERARCHY_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: payload.results,
        hierarchyById: sortById(state.entitiesById, payload.results),
        next: payload.next,
        error: null
      };
    case InstitutionActions.LOAD_INSTITUTION_HIERARCHY_FAILURE:
      return {
        ...state,
        loading: false,
        error: handleError(payload)
      };
    case InstitutionActions.LOAD_INSTITUTION_HIERARCHY_BY_ID:
      return {
        ...state,
        loadingChildById: payload,
        error: null
      };
    case InstitutionActions.LOAD_INSTITUTION_HIERARCHY_BY_ID_SUCCESS:
      let newState = {
        ...state.hierarchyById,
        [payload.id]: {
          ...state.hierarchyById[payload.id],
          ...payload
        }
      };

      payload.children.map((child) => {
        newState = {
          ...newState,
          [child.id]: child
        };
      });

      return {
        ...state,
        loadingChildById: null,
        hierarchyById: newState,
        error: null
      };
    case InstitutionActions.LOAD_INSTITUTION_HIERARCHY_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loadingChildById: null,
        error: handleError(payload)
      });
    case InstitutionActions.LOAD_MORE_INSTITUTIONS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.LOAD_MORE_INSTITUTIONS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entities: [...state.entities, ...payload.results],
        hierarchyById: sortById(state.hierarchyById, payload.results),
        next: payload.next
      });
    case InstitutionActions.LOAD_MORE_INSTITUTIONS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.LOAD_INSTITUTION_BY_ID:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.LOAD_INSTITUTION_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesById: Object.assign({}, state.entitiesById, {
          [payload.id]: payload
        }),
        error: null
      });
    case InstitutionActions.LOAD_INSTITUTION_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    case InstitutionActions.SEARCH_INSTITUTION:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.SEARCH_INSTITUTION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        searchResults: payload.results,
        error: null
      });
    case InstitutionActions.SEARCH_INSTITUTION_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.CLEAR_INSTITUTION_SEARCH:
      return Object.assign({}, state, {
        loading: false,
        searchResults: null,
        error: null
      });
    case InstitutionActions.UPDATE_INSTITUTION:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.UPDATE_INSTITUTION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        entitiesById: {
          [payload.id]: Object.assign({}, payload)
        },
        error: null
      });
    case InstitutionActions.UPDATE_INSTITUTION_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.LOAD_INSTITUTION_SERVICE_RECEIVERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.LOAD_INSTITUTION_SERVICE_RECEIVERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        serviceReceivers: Object.assign({}, state.serviceReceivers, {
          [payload.institution_id]: payload.serviceReceivers
        }),
        error: null
      });
    case InstitutionActions.LOAD_INSTITUTION_SERVICE_RECEIVERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.ADD_INSTITUTION_SERVICE_RECEIVERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.ADD_INSTITUTION_SERVICE_RECEIVERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        serviceReceivers: Object.assign({}, state.serviceReceivers, {
          [payload.institution_id]: payload.serviceReceivers
        }),
        error: null
      });
    case InstitutionActions.ADD_INSTITUTION_SERVICE_RECEIVERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.REMOVE_INSTITUTION_SERVICE_RECEIVER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.REMOVE_INSTITUTION_SERVICE_RECEIVER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        serviceReceivers: Object.assign({}, state.serviceReceivers, {
          [payload.institution_id]: payload.serviceReceivers
        }),
        error: null
      });
    case InstitutionActions.REMOVE_INSTITUTION_SERVICE_RECEIVER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.LOAD_INSTITUTION_EMPLOYEES:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.LOAD_INSTITUTION_EMPLOYEES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        employees: Object.assign({}, state.employees, {
          [payload.institution_id]: payload.employees
        }),
        error: null
      });
    case InstitutionActions.LOAD_INSTITUTION_EMPLOYEES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.ADD_INSTITUTION_EMPLOYEES:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.ADD_INSTITUTION_EMPLOYEES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        employees: Object.assign({}, state.employees, {
          [payload.institution_id]: payload.employees
        }),
        error: null
      });
    case InstitutionActions.ADD_INSTITUTION_EMPLOYEES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.REMOVE_INSTITUTION_EMPLOYEE:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.REMOVE_INSTITUTION_EMPLOYEE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        employees: Object.assign({}, state.employees, {
          [payload.institution_id]: payload.employees
        }),
        error: null
      });
    case InstitutionActions.REMOVE_INSTITUTION_EMPLOYEES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    case InstitutionActions.LOAD_INSTITUTIONS_BY_USER:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case InstitutionActions.LOAD_INSTITUTIONS_BY_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        institutionsByUser: Object.assign({}, state.institutionsByUser, {
          [payload.user_id]: payload.institutions
        }),
        error: null
      });
    case InstitutionActions.LOAD_INSTITUTIONS_BY_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: handleError(payload)
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: InstitutionState) => state.loading;
export const getEntities = (state: InstitutionState) => state.entities;
export const getSearchResults = (state: InstitutionState) => state.searchResults;
export const getEntitiesById = (state: InstitutionState) => state.entitiesById;
export const getError = (state: InstitutionState) => state.error;
export const getNext = (state: InstitutionState) => state.next;
export const getServiceReceivers = (state: InstitutionState) => state.serviceReceivers;
export const getEmployees = (state: InstitutionState) => state.employees;
export const getInstitutionsByUser = (state: InstitutionState) => state.institutionsByUser;
export const getLoadingChildById = (state: InstitutionState) => state.loadingChildById;
export const getHierarchyById = (state: InstitutionState) => state.hierarchyById;

import {Action} from '@ngrx/store';

export const LOAD_BILLING_RESPONSIBILITIES_BY_ID = 'LOAD_BILLING_RESPONSIBILITIES_BY_ID';
export const LOAD_BILLING_RESPONSIBILITIES_BY_ID_SUCCESS = 'LOAD_BILLING_RESPONSIBILITIES_BY_ID_SUCCESS';
export const LOAD_BILLING_RESPONSIBILITIES_BY_ID_FAILURE = 'LOAD_BILLING_RESPONSIBILITIES_BY_ID_FAILURE';
export const CREATE_BULK_BILLING_INFO = 'CREATE_BULK_BILLING_INFO';
export const CREATE_BULK_BILLING_INFO_SUCCESS = 'CREATE_BULK_BILLING_INFO_SUCCESS';
export const CREATE_BULK_BILLING_INFO_FAILURE = 'CREATE_BULK_BILLING_INFO_FAILURE';

export class LoadBillingResponsibilitiesById implements Action {
  readonly type = LOAD_BILLING_RESPONSIBILITIES_BY_ID;

  constructor(public payload: string) {}
}

export class LoadBillingResponsibilitiesByIdSuccess implements Action {
  readonly type = LOAD_BILLING_RESPONSIBILITIES_BY_ID_SUCCESS;

  constructor(public payload: any) {}
}

export class LoadBillingResponsibilitiesByIdFailure implements Action {
  readonly type = LOAD_BILLING_RESPONSIBILITIES_BY_ID_FAILURE;

  constructor(public payload: string) {}
}

export class CreateBulkBillingInfo implements Action {
  readonly type = CREATE_BULK_BILLING_INFO;

  constructor(public payload: {info: any, userIds: string[]}) {}
}

export class CreateBulkBillingInfoSuccess implements Action {
  readonly type = CREATE_BULK_BILLING_INFO_SUCCESS;

  constructor(public payload: any) {}
}

export class CreateBulkBillingInfoFailure implements Action {
  readonly type = CREATE_BULK_BILLING_INFO_FAILURE;

  constructor(public payload: string) {}
}

export type All = LoadBillingResponsibilitiesById | LoadBillingResponsibilitiesByIdSuccess | LoadBillingResponsibilitiesByIdFailure |
  CreateBulkBillingInfo | CreateBulkBillingInfoSuccess | CreateBulkBillingInfoFailure;

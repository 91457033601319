import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Municipality} from '../interfaces';

@Injectable()
export class MunicipalityService extends ApiService {
  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  loadByCountry(country: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}municipalities?country=${country}&limit=450&offset=0`;
    return this._http.get(url);
  }

  loadMoreByCountry(url): Observable<any> {
    return this._http.get(url);
  }

  loadById(municipalityId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}municipalities/${municipalityId}`;
    return this._http.get(url);
  }

  update(municipality: Municipality): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}municipalities/${municipality.id}`;
    return this._http.patch(url, municipality);
  }

  create(municipality: Municipality): Observable<Municipality|any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}municipalities`;
    return this._http.post(url, municipality);
  }
}

import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {LocalStorage} from '@ngx-pwa/local-storage';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class BillingService extends ApiService {

  constructor(private _http: HttpClient, protected _ls: LocalStorage) {
    super(_ls);
  }

  loadBillingResponsibilities(responsibleId: string): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}billing/responsible/${responsibleId}`;
    return this._http.get(url)
      .pipe(
        map((results: any[]) => ({responsibleId, results}))
      );
  }

  createBulkBillingInfo(info: string, userIds: string[]): Observable<any> {
    const url = `${environment['API_URL']}${environment['API_VERSION']}billing`;
    return forkJoin(userIds.map((userId) => {
      return this._http.post(url, Object.assign({}, info, {service_receiver_id: userId}));
    }));
  }
}

import * as EulaActions from './eula.actions';

type Action = EulaActions.All;

export interface EulaState {
  loading: boolean;
  respondersByVersion: any;
  error: string;
}

const initialState = {
  loading: false,
  respondersByVersion: null,
  error: null,
};

export function reducer(state: EulaState = initialState, {type, payload}: Action): EulaState {
  switch (type) {
    case EulaActions.LOAD_EULA_RESPONDERS:
      return Object.assign({}, state, {
        loading: true,
        error: null
      });
    case EulaActions.LOAD_EULA_RESPONDERS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        respondersByVersion: payload
      });
    case EulaActions.LOAD_EULA_RESPONDERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: payload.error
      });
    default:
      return state;
  }
}

export const getLoadingState = (state: EulaState) => state.loading;
export const getRespondersByVersion = (state: EulaState) => state.respondersByVersion;
export const getError = (state: EulaState) => state.error;

import {Component, Input, OnInit} from '@angular/core';
import {UnAuth} from '../../store/auth/auth.actions';
import {getUiShowDropDown, IAppState} from '../../store';
import {select, Store} from '@ngrx/store';
import {ToggleNavBarMenuDropDown} from '../../store/ui/ui.actions';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {
  @Input() authUser: any;
  showSubMenu$: Observable<boolean>;

  constructor(private store: Store<IAppState>) {}

  ngOnInit(): void {
    this.showSubMenu$ = this.store.pipe(select(getUiShowDropDown));
  }

  logout(): void {
    this.store.dispatch(new UnAuth());
  }

  toggleSubMenu(): void {
    this.store.dispatch(new ToggleNavBarMenuDropDown());
  }
}
